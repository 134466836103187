<template>
  <section class="py-4 bg-lb">
    <!-- <q-header level="2" sweep data-animated="animate__zoomIn">
      Профильные <b>СМИ</b>
      <br>
      и <b>рейтинги</b> о
      <span class="position-relative">Qubity<img alt="" class="lines position-absolute"
                                                 src="@/assets/images/site/icons/grebeshki_bottom.svg"></span>
    </q-header>
    <div class="w-100 mt-3">
      <vue-marquee :showProgress="false" class="news-line">
        <vue-marquee-slide v-for="(item, i) in news_list" :key="i" class="crypto-news__wrapper">
          <crypto-news-element :img="item.img"/>
        </vue-marquee-slide>
      </vue-marquee>

      <vue-marquee v-if="!isMobile" :direction="'right'" :showProgress="false" style="height:120px">
        <vue-marquee-slide v-for="(item, i) in news_list" :key="i" class="crypto-news__wrapper">
          <crypto-news-element :img="item.img"/>
        </vue-marquee-slide>
      </vue-marquee>
    </div> -->
    <b-container class=container-px-lg>
      <div class="row mt-5">
        <div class="col-12 col-md-8 offset-md-2 offset-lg-0 col-lg-6 col_crypto_man_photo">
          <img alt="" class="w-100" src="@/assets/images/site/crypto_man.png">
        </div>
        <div class="col-12 col-lg-6">
          <q-header class="text-center text-lg-left" size="l">
            <b>Qubity.io</b> {{$t('lending.media-ratings.header')}}
          </q-header>
          <div class="accent-text text-center text-lg-left">
            <div>
              {{$t('lending.media-ratings.p1')}}
            </div>
            <div class="mt-2">
             {{$t('lending.media-ratings.p2')}}
            </div>
          </div>
          <div class="d-flex">
            <q-btn class="mt-3 mx-auto ml-lg-0" to="/register" variant="primary">{{$t('lending.btns.start-earning')}}</q-btn>
          </div>
        </div>
      </div>
    </b-container>
  </section>
</template>

<script>
import CryptoNewsElement from "@/views/Main/main-design-v2/views/home/components/CryptoNewsElement";
import img from '@/assets/images/icons/cryptonewsx4.png'

export default {
  name: "media-ratings",
  components: {
    CryptoNewsElement,
  },
  data() {
    return {
      news_list: [
        {
          img: img,
          text: "Заработать на криптовалюте с Qubity.io сможет любой..."
        },
        {
          img: img,
          text: "Заработать на криптовалюте с Qubity.io сможет любой..."
        },
        {
          img: img,
          text: "Заработать на криптовалюте с Qubity.io сможет любой..."
        },
        {
          img: img,
          text: "Заработать на криптовалюте с Qubity.io сможет любой..."
        },
        {
          img: img,
          text: "Заработать на криптовалюте с Qubity.io сможет любой..."
        },
        {
          img: img,
          text: "Заработать на криптовалюте с Qubity.io сможет любой..."
        },
      ],
    }
  },
  computed: {
    isMobile: function () {
      return window.innerWidth < 768
    }
  }
}
</script>

<style lang="scss" scoped>
.lines {
  width: 30px;
  height: 30px;
  top: 50%;
}

.col_crypto_man_photo {
  display: flex;
  align-items: center;
}

.news-line {
  height: 120px
}

.crypto-news__wrapper {
  width: 520px;
}

@media screen and (max-width: 768px) {
  .crypto-news__wrapper {
    width: 290px;
  }

  .news-line {
    height: 200px
  }
}

@keyframes move-news {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(50%);
  }
}
</style>
