<template>
  <div class="client-review-card position-relative open-review__wrapper" :style="`background: url('` + bgImg + `') center center / cover`">
    <div class="client-review-card__inner w-100 d-flex flex-column" >
      <img alt="" class="mx-auto play-img mt-auto mb-auto" src="@/assets/images/icons/review_play.svg"
           style="width: 65px" @click="playReview">
      <div>
        {{ $t(reviewShort) }}
      </div>
      <div class="cursor-pointer full-text-link" @click="openFullTextReview">
        {{$t("lending.client-reviews.read-feedback")}}
      </div>
    </div>
    <div class="video-frame position-absolute" :class="isOpened && 'open-video'">
      <iframe width="100%" height="100%" :src="videoSrc" title="YouTube video player" frameborder="0" autoplay allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "client-review-card",
  props: {
    videoSrc: {type: String, required: true},
    reviewShort: {type: String, required: true},
    reviewFull: {type: String, required: true},
    reviewerName: {type: String, default: "Анонимный пользователь"},
    bgImg: {type: String, default: require("@/assets/images/review-bg-mock.png")}
  },
  data() {
    return {
      isOpened: false,
    }
  },
  methods: {
    playReview() {
      this.isOpened = !this.isOpened
    },
    openFullTextReview() {
      this.$bvModal.show("client_review_modal")
      this.$emit("select-review", {text: this.$t(this.reviewFull), name: this.reviewerName})
    }
  }
}
</script>

<style lang="scss" scoped>
@supports (aspect-ratio: 1) {
  .client-review-card {
    aspect-ratio: 1;
    border-radius: 32px;
    overflow: hidden;
  }
}

@supports not (aspect-ratio: 1) {
  .client-review-card {
    column-count: 1;
    border-radius: 32px;
    overflow: hidden;

    .client-review-card__inner {
      position: absolute;
    }
  }


  .client-review-card::before {
    content: "";
    float: left;
    padding-bottom: calc(100% / (1 / 1));
  }
}

.client-review-card__inner {
  height: 100%;
  padding: 24px;
  background: rgba(0, 0, 0, .6);
  color: white;
}

.review-text {
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 0;
  background: white;
  padding: 0 24px;
  overflow: hidden;
  transition: all .3s ease;
}

.open-review {
  height: 100%;
  padding: 24px;

  .text {
    font-size: 18px;
    margin-top: 16px;
  }
}

.open-review__wrapper {
  border: solid 1px #d9d9d9;
  overflow: hidden;
}

.play-img {
  transition: all .3s ease;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
}

.full-text-link {
  text-decoration: underline;
}

.user-info-card {
  img {
    object-fit: cover;
    border-radius: 100px;
    width: 48px;
  }

  span {
    font-weight: normal;
    font-size: 18px;
  }
}

.sound-wave {
  display: flex;

  div {
    transition: all .3s ease;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 3px;
    width: 3px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.18);
  }
}

@media screen and (max-width: 768px) {
  .sound-wave {
    div {
      margin-right: 2px;
    }
  }
}

.filled-sw {
  background: rgba(0, 0, 0, 0.32) !important;
}

.audio-review-card {
  border: 1px solid #d5d5d5;
}

.play-audio-review {
  border: 1px black solid;
  outline: none;
  border-radius: 50px;
  background: white;
  display: flex;
  padding: 8px;
  width: 30px;
  height: 30px;

  img {
    margin: auto;
  }
}

.video-frame {
  top: 0;
  left: 0;
  overflow: hidden;
  height: 0;
  width: 100%;
  transition: all .3s ease;
}

.open-video {
  height: 100%;
}
</style>

<style>
/* moving */
.spin-fade-move {
  transition: all 600ms ease-in-out 50ms;
}

/* appearing */
.spin-fade-enter-active {
  transition: all 400ms ease-out;
}

/* disappearing */
.spin-fade-leave-active {
  transition: all 200ms ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disappear to */
.spin-fade-enter,
.spin-fade-leave-to {
  opacity: 0;
  transform: rotate(180deg);
}
</style>
