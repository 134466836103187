<template>
  <div class="row">
    <div class="col-12 col-lg-6 calculator-sections-divider">
      <form-view :current_price="current_price" :diff_percent="diff_percent" :market_price_percent="market_price_percent"
                 :period="period" :period_profit="period_profit" :total_btc_price="total_btc_price"
                 :total_ths="total_ths" @dataChanged="dataChanged"/>
    </div>
    <div class="col-12 col-lg-6">
      <results-view :current_price="current_price" :diff_percent="diff_percent"
                    :market_price_percent="market_price_percent" :period="period" :period_profit="period_profit"
                    :total_btc_price="total_btc_price" :total_ths="total_ths"/>
    </div>
  </div>
</template>

<script>
import FormView from "./form"
import ResultsView from "./results"

export default {
  metaInfo() {
    const title = this.$t('title-pages.hashrate-rental');
    return {
      title: title,
      titleTemplate: null,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Платформа Qubity предоставляет в аренду мощность майнингового оборудования с включенным сервисным обслуживанием в контракт. Аренда хешрейта и майнинг возможны с любой суммы. На сайте представлен калькулятор майнинга по хешрейту с прогнозируемыми значениями и ожидаемым доходом'
        }
      ]
    }
  },
  data() {
    return {
      selectedTabIndex: 0,
      total_btc_price: 0,
      period_profit: 0,
      total_ths: 0,
      current_price: 0,
      period: 24,
      diff_percent: 1.77,
      market_price_percent: 3,
    }
  },
  methods: {
    dataChanged(args) {
      this.total_btc_price = args.total_btc_price;
      this.period_profit = args.period_profit;
      this.total_ths = args.total_ths;
      this.current_price = args.current_price;
      this.period = args.period;
    },

    forecastDataChanged(args) {
      this.diff_percent = args.difficult_percent;
      this.market_price_percent = typeof args.market_price_percent == 'number' ? args.market_price_percent : 0;
    },
  },
  components: {
    FormView,
    ResultsView,
  },
  watch: {},
  mounted() {

  }

}

</script>

<style>
.calculator-sections-divider {
  border-bottom: solid 1px;
  padding-bottom: 25px !important;
  border-color: rgba(0, 0, 0, .2) !important;
  margin-bottom: 24px;
}

@media screen and (min-width: 992px) {
  .calculator-sections-divider {
    padding-bottom: 0 !important;
    border-right: solid 1px;
    border-bottom: none;
    margin-bottom: 0;
  }
}
</style>
