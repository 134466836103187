<template>
  <b-col v-if="item.type == 1" class="my-1" cols="12" xl="4">
    <b-col class="tariff-card mx-auto d-flex justify-content-between flex-column px-3">
      <b-row class="mt-1 d-block">
        <b-row class="flex-row justify-content-between mt-1">
          <b-col class="flex-row d-flex my-auto" cols="12" md="6">
            <q-header :centered="false" color="var(--text-primary-color)" level="5" size="">
              <b>{{ $i18n.locale === 'ru' ? item.title : item.title_en || item.title }}</b>
            </q-header>
            <q-header :centered="false" color="var(--theme-accent-light)" level="5" size="" style="margin-left: 5px;">
              <b>{{ item.ths }}&nbsp;TH/s</b>
            </q-header>
          </b-col>
          <p class="bonus-label mx-1 mx-md-0">{{ $i18n.locale === 'ru' ? item.caption : item.caption_en || item.caption }}</p>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr class="my-1">
          </b-col>
        </b-row>
        <b-col cols="12">
          <b-row class="justify-content-between prop">
            <p class="name">{{$t('offer-card.contract-value')}}</p>
            <p>
              {{ item.old_ths.formatMoney(2, " ", " ") }} TH/s
            </p>
          </b-row>
          <b-row class="justify-content-between prop">
            <p class="name">{{$t('offer-card.wheeling-charge')}}</p>
            <p>{{ (item.ths * 0.05).formatMoney(2," ", " ") }} $</p>
          </b-row>
          <b-row class="justify-content-between prop">
            <p class="name">{{$t('offer-card.contract-term')}}</p>
            <p>{{ item.period }} мес.</p>
          </b-row>
          <b-row class="justify-content-between prop">
            <p class="name">{{$t('offer-card.daily-income')}}</p>
            <p>{{ profit.formatMoney(2," "," ") }} $</p>
          </b-row>
          <b-row class="bold-prop justify-content-between">
            <p class="name">{{$t('offer-card.expected-income')}} ($)
              <feather-icon
                  v-b-popover.hover.top="$t('popovers.income')"
                  class="cursor-pointer info-hover text-muted"
                  icon="AlertCircleIcon"
                  style="margin: 3px"
              />
            </p>
            <p class="expected-income">{{ summary.formatMoney(2," "," ") }} $</p>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12 p-0">
          <hr class="my-0">
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col class="mx-0 px-0 cost d-flex justify-content-between" cols="12">
          <i18n path="offer-card.contract-price" class="my-auto" tag="p">
            <br>
          </i18n>
          <q-header :centered="false" class="d-inline-flex" color="var(--text-primary-color)" level="1" size="xl">
            {{ item.price_amount.formatMoney(0," "," ") }} $
            <span class="old-cost" v-if="item.old_price_amount && item.old_price_amount > 0">
              <s>{{ item.old_price_amount.formatMoney(0," "," ") }} $</s>
            </span>
          </q-header>
        </b-col>
      </b-row>
      <q-btn id="connect_contract" :block="true" class="my-1" variant="secondary" @click="order(item)">
        {{ $i18n.locale === 'ru' ? item.action : item.action_en || item.action }}
      </q-btn>
    </b-col>
  </b-col>
</template>

<script>

import miners from "@/modules/contents/miners"

export default {

  data() {
    return {
      miners,

      diff_percent: 0,
      market_price_percent: 6,

      individual_style: {
        width: this.width ? `${this.width}px` : '100%',
        height: this.height ? `${this.height}px` : '100%',
        overflow: 'hidden',
        margin: '0 auto',
        transform: "scale(2)"
      }
    }
  },
  props: {
    item: Object,
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    mediaViewStyle() {
      return {
        height: '100px',
        width: '100%',
        minWidth: '100%',
        backgroundImage: `url(${this.item.miner.medias[0].urls.thumb})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "white",
        borderRadius: "12px"
      }
    },
    order(  ) {

      if( this.isPreview ) return;

      // if( this.item.type === 1 || this.item.type === 3) {
      //   this.$router.push({
      //     name: "user-contracts-create",
      //     query: {
      //       'with-offer': this.item.id
      //     }
      //   });
      // } else if( this.item.type === 2 ) {
      //   this.$set( this.miners.cart, this.item.miner.id, { miner: this.item.miner, count: 1 });
      //   this.$router.push({
      //     name: "user-miners-checkout",
      //     query: {
      //       'with-offer': this.item.id
      //     }
      //   });
      // }

      if (this.item.type === 1) {
        sessionStorage.setItem("after:auth", JSON.stringify({
          "type": "offer",
          "data": {
            "with-offer": this.item.id
          }
        }));

        this.$router.push("/authorization");
      } else if (this.item.type === 2) {
        this.$set( this.miners.cart, this.item.miner.id, { miner: this.item.miner, count: 1, guarantee: { period: 'none', ths: false, amount: 0 } });
        sessionStorage.setItem("after:auth", JSON.stringify({
          "type": "offer-buy",
          "data": {
            "with-offer": this.item.id
          }
        }));
        
        this.$router.push("/authorization");
      }

    },
  },
  components: {

  },
  computed: {

    style() {
      if (!this.item.hasOwnProperty('special') || !this.isPreview) return {margin: '0 12px'};

      if (this.item.hasOwnProperty('special') && this.item.special === 'web') {
        if (this.item.type === 1) return {margin: '0px 0px 120px 0px'}
        else return {margin: '0px 0px 50px 0px'}
      } else if (this.item.hasOwnProperty('special') && this.item.special === 'individual') {
        if (this.item.type === 1) return {margin: '0px 0px 80px 0px'}
        else return {margin: '0px 0px 80px 0px'}
      }
    },

    fee() {
      return (this.period_profit * 0.025) * this.market_price;
    },

    profit_btc() {
      return (((84600 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.item.ths )) / ( this.$user.contracts.values.D * Math.pow(2, 32) )) );
    },

    profit() {
      return this.profit_btc * this.$blockchain.tickers.values.USD.last;
    },

    summary() {
      return (this.period_profit * this.market_price) - (this.energy_payout * this.item.period) - this.fee;
    },

    period_profit() {

      return (((84600 * 30 * this.item.period * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.item.ths )) / ( this.difficult * Math.pow(2, 32) )) );
    },

    period_profit_miner() {
      return (((84600 * 30 * 36 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.item.miner.hashrate )) / ( this.difficult * Math.pow(2, 32) )) );
    },

    market_price() {

      let temp = this.$blockchain.tickers.values.USD.last * ( this.market_price_percent / 100 );

      let growing = 0;
      if (this.item.type === 2) {
        growing = temp * 36;
      } else {
        growing = temp * this.item.period;
      }

      return this.$blockchain.tickers.values.USD.last + growing;

    },

    difficult() {

      let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
      let growing = 0;
      if (this.item.type === 2) {
        growing = temp * 36;
      } else {
        growing = temp * this.item.period;
      }
      let diff = parseInt(this.$user.contracts.values.D) + growing;

      if( diff <= 1000000000 ) {
        diff = 1000000000;
      }

      return parseInt(diff);

    },

    energy_payout() {
      return ( this.item.ths * ( this.$user.contracts.values.E * 24 * 30 ) );
    },

    performance() {
      return Math.trunc(this.item.miner.energy_usage / this.item.miner.hashrate);
    },

    daily_profit() {

      let hashrate = this.item.miner.hashrate;

      return ( ((84600 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * hashrate )) / ( this.$user.contracts.values.D * Math.pow(2, 32) )) ).toFixed(8);

    },

    daily_currency() {
      return (this.daily_profit * this.$blockchain.tickers.values["USD"].last);
    },

    summary_miner() {
      return this.period_profit_miner * this.market_price - this.fee_miner - this.service - this.energy_miner;
    },

    fee_miner() {
      return (this.period_profit_miner * 0.025) * this.market_price;
    },

    service() {
      return (this.period_profit_miner * 0.05) * this.market_price;
    },

    energy_miner() {

      let btc = this.item.miner.service_period * (((( (this.item.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 )) / this.$blockchain.tickers.values['USD'].last) ;

      return btc * this.market_price;

    },

  },
  watch: {

  },
  mounted() {

  }

}

</script>

<style lang="scss" scoped>
.prop {
  .name {
    color: var(--text-secondary-color);
  }

  p {
    line-height: .7;
  }

  color: var(--text-primary-color);
}

.bold-prop {
  color: #000;

  p {
    line-height: .7;
    font-size: 15px;
  }

  .expected-income {
    font-size: 22px;
  }
}

.tariff-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 32px;
  transition: .3s;
}

.tariff-card:hover {
  border: none;
  box-shadow: 0 64px 64px rgba(31, 47, 70, 0.12);
  transform: scale(1.02);
  width: 100%;
}

.bonus-label {
  background: #47BCAA;
  color: var(--theme-bg-light);
  padding: 5px 10px;
  border-radius: 50px;
  white-space: nowrap;
}

.old-cost {
  font-size: 50%;
}

.cost p {
  line-height: 1.2;
}

@media screen and (max-width: 1200px) {
  .tariff-card:hover {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: none;
  }
}
</style>

<style lang="scss">
#connect_contract {
  a {
    padding: 20px 12px !important;
  }
}
</style>
