<template>
  <div class="faq-item">
    <div class="faq-question d-flex justify-content-between" @click="toggleFaqItem">
      <span class="my-auto mr-1">
        <slot name="question">
        </slot>
      </span>
      <span class="icon">
        <img :class="!isOpen && 'icon-faq-plus'" alt="" class="icon-symbol" src="@/assets/images/icons/cross.svg">
      </span>
    </div>
    <b-collapse id="faq-item" :visible="isOpen" class="mt-2 faq-answer">
      <slot>
      </slot>
    </b-collapse>
    <hr>
  </div>
</template>

<script>
export default {
  name: "faq-item",
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleFaqItem() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-question {
  font-size: 24px;

  span {
    cursor: pointer;
  }

  .icon {
    transition: all .3s ease;
    border: solid 1px var(--text-primary-color);
    border-radius: 100px;
    aspect-ratio: 1;
    width: 32px;
    height: 32px;
    display: flex;
    padding: 4px;

    .icon-symbol {
      transition: all .3s ease;
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px){
  .faq-question {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px){
  .faq-question {
    font-size: 20px;
  }
}

.faq-answer {
  font-size: 18px;
}

.icon-faq-plus {
  transform: rotate(45deg);
}
</style>
