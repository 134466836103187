<template>
  <b-container>
    <b-col class="short-description text-center mx-auto" cols="12" md="10">
      <q-header class="mt-4" level="1" size="xxl">
      <i18n path="lending.short-description.header-a" tag="b">
        <br class="d-md-none">
        <span class="position-relative bitcoin zindex-1">Bitcoin<img alt="" class="stroke position-absolute w-100"
                                                                      src="@/assets/images/icons/stroke.svg"></span>
        <br class="d-none d-md-block d-lg-none">
      </i18n>
      <i18n path="lending.short-description.header-b" tag="span">
        <br class="d-md-none d-lg-block">
        <br class="d-md-none">
        <br class="d-none d-md-block d-lg-none"> 
        <br class="d-md-none d-lg-block">
      </i18n>
      </q-header>

      <p class="mt-1 w-100 mx-auto p-0 mx-0 subheader">
        <b>{{$t('lending.short-description.sub-header-a')}}</b><br class="d-lg-none">{{$t('lending.short-description.sub-header-b')}}<br>
        24/7&nbsp;{{$t('lending.short-description.sub-header-c')}}&nbsp;<b>{{$t('lending.short-description.sub-header-d')}}<br class="d-lg-none"> {{$t('lending.short-description.sub-header-e')}}</b>
      </p>
      <q-btn to="/register" class="mt-2" sweep variant="primary">{{$t('lending.btns.start-mining')}}</q-btn>
    </b-col>
    <img v-if="$i18n.locale == 'en'" alt="" class="w-100 d-block d-xl-none" src="@/assets/images/site/window-mobile_en.png">
    <img v-else alt="" class="w-100 d-block d-xl-none" src="@/assets/images/site/window-mobile.png">

    <img v-if="$i18n.locale == 'en'" alt="" class="w-100 d-none d-xl-block" src="@/assets/images/site/window_en.png">
    <img v-else alt="" class="w-100 d-none d-xl-block" src="@/assets/images/site/window.png">
  </b-container>
</template>

<script>
import QBtn from "@/views/Main/main-design-v2/components/QBtn.vue"
import QHeader from "@/views/Main/main-design-v2/components/QHeader.vue"

export default {
  name: "short-description",
  components: {
    "q-btn": QBtn,
    "q-header": QHeader
  }
}
</script>

<style lang="scss" scoped>
.short-description {
  q-header {
    color: var(--theme-accent-dark);
  }

  p {
    font-size: 20px;
    line-height: 1.4;
  }
  @media screen and (max-width: 768px) {
    p {
      font-size: 16px;
    }
  }

  .stroke {
    z-index: -1;
  }
}

</style>
