<template>
  <section class="pt-4 pb-3" style="background-color: #181818">
    <b-container class="container-px-lg">
      <b-row>
        <b-col cols="12" lg="8">
          <h6 style="color: #4A76F4;">{{$t('lending.data-center.header-place')}}</h6>
          <q-header :centered="false" color="#fff" level="1" size="xl">
            {{$t('lending.data-center.header-a')}}
            <b>{{$t('lending.data-center.header-b')}} <br class="mobile-word-break">{{$t('lending.data-center.header-c')}}
              <span
                class="position-relative zindex-1"><img alt="" class="text-ellipse position-absolute w-100 zindex-minus-1" src="@/assets/images/site/ellipse.svg">{{$t('lending.data-center.header-d')}}</span></b>
          </q-header>
        </b-col>
        <b-col class="d-none d-lg-block" cols="4">
          <svg width="314" height="154" viewBox="0 0 314 154" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="77" r="7.5" stroke="white"/>
            <circle cx="36" cy="77" r="19.5" stroke="white"/>
            <text x="92" y="50" fill="white" transform="rotate(11)" font-size="24" font-weight="500" class="svg-text">
              {{this.$user.contracts.values.K}}$
            </text>
            <text v-if="$i18n.locale == 'ru'" x="88" y="73" fill="white" transform="rotate(11)" font-size="12" font-weight="300" class="svg-text">
              за КВт/час
            </text>
            <text v-else x="93" y="73" fill="white" transform="rotate(11)" font-size="12" font-weight="300" class="svg-text">
              for kWe/h
            </text>
            <circle cx="108" cy="77" r="51.5" stroke="white"/>
            <text v-if="$i18n.locale == 'ru'" x="208" y="0" fill="white" transform="rotate(12)" font-size="24" font-weight="500" class="svg-text">
              90 МВт
            </text>
            <text v-else x="208" y="0" fill="white" transform="rotate(12)" font-size="24" font-weight="500" class="svg-text">
              90 MW
            </text>
            <text v-if="$i18n.locale == 'ru'" x="185" y="30" fill="white" transform="rotate(12)" font-size="12" font-weight="300" class="svg-text">
              Доступная мощность
            </text>
             <text v-else x="195" y="30" fill="white" transform="rotate(12)" font-size="12" font-weight="300" class="svg-text">
              Available capacity 
            </text>
            <text v-if="$i18n.locale == 'ru'" x="195" y="45" fill="white" transform="rotate(12)" font-size="12" font-weight="300" class="svg-text">
              для размешения
            </text>
            <text v-else x="205" y="45" fill="white" transform="rotate(12)" font-size="12" font-weight="300" class="svg-text">
              for equipment 
            </text>
            <text v-if="$i18n.locale == 'ru'" x="200" y="60" fill="white" transform="rotate(12)" font-size="12" font-weight="300" class="svg-text">
              оборудования
            </text>
             <text v-else x="215" y="60" fill="white" transform="rotate(12)" font-size="12" font-weight="300" class="svg-text">
              placement
            </text>
            <circle cx="237" cy="77" r="76.5" stroke="white"/>
            <defs>
              <clipPath id="clip0_574_12413">
                <rect width="147" height="92" fill="white" transform="translate(164 37)"/>
              </clipPath>
            </defs>
          </svg>

        </b-col>
        <b-col class="d-none d-md-block d-lg-none" cols="12">
          <svg class="w-100 my-1 pointer-events-none" width="704" height="112" viewBox="0 0 704 112" fill="none" xmlns="http://www.w3.org/2000/svg">
            <text v-if="$i18n.locale == 'ru'" x="20" y="50" font-size="24" fill="white" font-weight="700" dx="110">
              90 МВт
            </text>
            <text v-else x="20" y="50" font-size="24" fill="white" font-weight="700" dx="110">
              90 MW
            </text>
            <text v-if="$i18n.locale == 'ru'" x="-50" y="70" font-size="12" fill="white" font-weight="300" dx="110">
              Доступная мощность для размешения
            </text>
             <text v-else x="-50" y="70" font-size="12" fill="white" font-weight="300" dx="110">
              Available capacity for equipment 
            </text>
            <text v-if="$i18n.locale == 'ru'" x="20" y="85" font-size="12" fill="white" font-weight="300" dx="110">
              оборудования
            </text>
            <text v-else x="20" y="85" font-size="12" fill="white" font-weight="300" dx="110">
              placement
            </text>
            <path d="M339.5 56C339.5 63.5105 334.88 70.7473 326.333 77.4033C317.793 84.0542 305.405 90.0656 290.052 95.1231C259.351 105.236 216.906 111.5 170 111.5C123.094 111.5 80.6494 105.236 49.9483 95.1231C34.5951 90.0656 22.2065 84.0542 13.6667 77.4033C5.1205 70.7473 0.5 63.5105 0.5 56C0.5 48.4895 5.1205 41.2527 13.6667 34.5967C22.2065 27.9458 34.5951 21.9344 49.9483 16.8769C80.6494 6.76362 123.094 0.5 170 0.5C216.906 0.5 259.351 6.76362 290.052 16.8769C305.405 21.9344 317.793 27.9458 326.333 34.5967C334.88 41.2527 339.5 48.4895 339.5 56Z" stroke="white"/>
            <text x="20" y="50" font-size="24" fill="white" font-weight="700" dx="410" dy="8">
              {{this.$user.contracts.values.K}}$
            </text>
            <text v-if="$i18n.locale == 'ru'" x="20" y="70" font-size="12" fill="white" font-weight="300" dx="410" dy="8">
              за КВт/час
            </text>
            <text v-else x="20" y="70" font-size="12" fill="white" font-weight="300" dx="410" dy="8">
              for kWe/h
            </text>
            <path d="M582.5 55.5C582.5 61.8722 579.199 67.9966 573.113 73.622C567.026 79.2479 558.191 84.3373 547.231 88.6219C525.316 97.1898 495.005 102.5 461.5 102.5C427.995 102.5 397.684 97.1898 375.769 88.6219C364.809 84.3373 355.974 79.2479 349.887 73.622C343.801 67.9966 340.5 61.8722 340.5 55.5C340.5 49.1278 343.801 43.0034 349.887 37.378C355.974 31.7521 364.809 26.6627 375.769 22.3781C397.684 13.8102 427.995 8.5 461.5 8.5C495.005 8.5 525.316 13.8102 547.231 22.3781C558.191 26.6627 567.026 31.7521 573.113 37.378C579.199 43.0034 582.5 49.1278 582.5 55.5Z" stroke="white"/>
            <path d="M668.5 56C668.5 61.2123 663.938 66.0875 656.195 69.6888C648.493 73.271 637.818 75.5 626 75.5C614.182 75.5 603.507 73.271 595.805 69.6888C588.062 66.0875 583.5 61.2123 583.5 56C583.5 50.7877 588.062 45.9125 595.805 42.3112C603.507 38.729 614.182 36.5 626 36.5C637.818 36.5 648.493 38.729 656.195 42.3112C663.938 45.9125 668.5 50.7877 668.5 56Z" stroke="white"/>
            <path d="M703.5 56C703.5 57.894 701.799 59.7703 698.666 61.2021C695.579 62.6137 691.278 63.5 686.5 63.5C681.722 63.5 677.421 62.6137 674.334 61.2021C671.201 59.7703 669.5 57.894 669.5 56C669.5 54.106 671.201 52.2297 674.334 50.7979C677.421 49.3863 681.722 48.5 686.5 48.5C691.278 48.5 695.579 49.3863 698.666 50.7979C701.799 52.2297 703.5 54.106 703.5 56Z" stroke="white"/>
          </svg>

        </b-col>
        <b-col class="d-md-none d-block my-2" cols="12">
          <svg class="w-100 my-1 pointer-events-none" width="334" height="112" viewBox="0 0 334 112" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M316.5 62C316.5 71.0096 307.214 78.5 295.5 78.5C283.786 78.5 274.5 71.0096 274.5 62C274.5 52.9904 283.786 45.5 295.5 45.5C307.214 45.5 316.5 52.9904 316.5 62Z" stroke="white"/>
            <path d="M333.5 62C333.5 65.5034 330.013 68.5 325.5 68.5C320.987 68.5 317.5 65.5034 317.5 62C317.5 58.4966 320.987 55.5 325.5 55.5C330.013 55.5 333.5 58.4966 333.5 62Z" stroke="white"/>
            <text v-if="$i18n.locale == 'ru'" x="20" y="50" font-size="24" fill="white" font-weight="700" dx="20" dy="-4">
              90 МВт
            </text>
            <text v-else x="20" y="50" font-size="24" fill="white" font-weight="700" dx="20" dy="-4">
              90 MW
            </text>
            <text v-if="$i18n.locale == 'ru'" x="20" y="65" fill="white" font-size="12" font-weight="300" class="svg-text">
              Доступная мощность
            </text>
            <text v-else x="20" y="65" fill="white" font-size="12" font-weight="300" class="svg-text">
              Available capacity 
            </text>
            <text v-if="$i18n.locale == 'ru'" x="35" y="80" fill="white" font-size="12" font-weight="300" class="svg-text">
              для размешения
            </text>
            <text v-else x="35" y="80" fill="white" font-size="12" font-weight="300" class="svg-text">
              for equipment 
            </text>
            <text v-if="$i18n.locale == 'ru'" x="40" y="95" fill="white" font-size="12" font-weight="300" class="svg-text">
              оборудования
            </text>
            <text v-else x="40" y="95" fill="white" font-size="12" font-weight="300" class="svg-text">
              placement
            </text>
            <path d="M161.5 56C161.5 71.2532 152.558 85.116 137.991 95.1867C123.427 105.256 103.279 111.5 81 111.5C58.7212 111.5 38.5732 105.256 24.0087 95.1867C9.44215 85.116 0.5 71.2532 0.5 56C0.5 40.7468 9.44215 26.884 24.0087 16.8133C38.5732 6.74399 58.7212 0.5 81 0.5C103.279 0.5 123.427 6.74399 137.991 16.8133C152.558 26.884 161.5 40.7468 161.5 56Z" stroke="white"/>
            <text  x="20" y="50" font-size="24" fill="white" font-weight="700" dx="167" dy="13">
              {{this.$user.contracts.values.K}}$
            </text>
            <text v-if="$i18n.locale == 'ru'" x="20" y="68" font-size="12" fill="white" font-weight="300" dx="167" dy="13">
              за КВт/час
            </text>
            <text v-else x="20" y="68" font-size="12" fill="white" font-weight="300" dx="167" dy="13">
              for kWe/h
            </text>
            <path d="M274.5 60.4074C274.5 71.0787 268.244 80.7933 258.021 87.8614C247.799 94.928 233.651 99.3148 218 99.3148C202.349 99.3148 188.201 94.928 177.979 87.8614C167.756 80.7933 161.5 71.0787 161.5 60.4074C161.5 49.7361 167.756 40.0215 177.979 32.9534C188.201 25.8868 202.349 21.5 218 21.5C233.651 21.5 247.799 25.8868 258.021 32.9534C268.244 40.0215 274.5 49.7361 274.5 60.4074Z" stroke="white"/>
          </svg>

        </b-col>
      </b-row>
      <b-row class="pt-3" style="margin: 20px 0;">
        <b-col class="d-flex flex-column justify-content-between" cols="12" md="6">
          <DataCenterCollapse
              :image="AsicMinerImage"
              :header="$t('lending.data-center.collapse-h-1')"
              :text="$t('lending.data-center.collapse-t-1')"
          />
          <hr class="w-100" style="border-top: #434343 1px solid">
        </b-col>
        <b-col class="d-flex flex-column justify-content-between" cols="12" md="6">
          <DataCenterCollapse
              :image="CustomerService"
              :header="$t('lending.data-center.collapse-h-2')"
              :text="$t('lending.data-center.collapse-t-2')"
          />
          <hr class="w-100" style="border-top: #434343 1px solid">
        </b-col>
        <b-col class="d-flex flex-column justify-content-between" cols="12" md="6">
          <DataCenterCollapse
              :image="TempControl"
              :header="$t('lending.data-center.collapse-h-3')"
              :text="$t('lending.data-center.collapse-t-3')"
          />
          <hr class="w-100" style="border-top: #434343 1px solid">
        </b-col>
        <b-col class="d-flex justify-content-between flex-column" cols="12" md="6">
          <DataCenterCollapse
              :image="Router"
              :header="$t('lending.data-center.collapse-h-4')"
              :text="$t('lending.data-center.collapse-t-4')"
          />
          <hr class="w-100" style="border-top: #434343 1px solid">
        </b-col>
      </b-row>
      <b-row>
        <b-col class="p-1 p-md-3" cols="12" style="border-radius: 35px; overflow-x: hidden">
          <b-carousel v-model="slide"
                      :interval="4000"
                      class="miner-slider"
                      controls
                      @sliding-start="onSlideStart"
                      @sliding-end="onSlideEnd"
          >
            <b-carousel-slide :img-src="MiningCenter"/>
            <b-carousel-slide :img-src="MiningCenter2"/>
            <b-carousel-slide :img-src="MiningCenter3"/>
          </b-carousel>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>

import DataCenterCollapse from "@/views/Main/main-design-v2/views/home/components/DataCenterCollapse";
import AsicMinerImage from "@/assets/images/site/icons/asic-mining.svg"
import CustomerService from "@/assets/images/site/icons/customer-service.svg"
import TempControl from "@/assets/images/site/icons/temperature-control.svg"
import Router from "@/assets/images/site/icons/router.svg"
import MiningCenter from '@/assets/images/site/miner-center.png'
import MiningCenter2 from '@/assets/images/site/miner-center-2.jpg'
import MiningCenter3 from '@/assets/images/site/miner-center-3.jpg'

export default {
  name: "data-center",
  components: {DataCenterCollapse},
  data() {
    return {
      isOpen: false,
      AsicMinerImage: AsicMinerImage,
      CustomerService: CustomerService,
      TempControl: TempControl,
      Router: Router,
      MiningCenter: MiningCenter,
      MiningCenter2: MiningCenter2,
      MiningCenter3: MiningCenter3,
      slide: 0,
      sliding: null
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  },
}
</script>

<style lang="scss">
.miner-slider {
  .carousel-inner {
    border-radius: 30px;
    overflow: hidden;
  }

  .carousel-control-next,
  .carousel-control-prev {
    transition: all .3s ease;
    opacity: 1;

    @media screen and (min-width: 768px) {
      & {
        opacity: 0;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: var(--theme-accent-light) !important;
    background-size: 40%;
    border-radius: 50%;
    padding: 20%;
  }

  @media screen and (max-width: 768px) {
    .carousel-control-prev {
      left: -4vw;
    }

    .carousel-control-next {
      right: -4vw;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      padding: 35%;
    }
  }

  .carousel-control-prev-icon {
    background-image: url('~@/assets/images/site/icons/arrow_left.svg');
  }

  .carousel-control-next-icon {
    background-image: url('~@/assets/images/site/icons/arrow_right.svg');
  }
}
</style>

<style lang="scss" scoped>
.miner_small_text {
  color: white;
  line-height: 22px;
  width: 80%
}

.miner_icon {
  width: 50px;
}

  .carousel-item {
    height: 200px
  }

  @media screen and (min-width: 1200px) {
    .carousel-item {
      height: 660px
    }
  }
</style>
