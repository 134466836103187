<template>
  <section class="py-3">
    <div class="container">
      <hr class="mb-2">
    </div>
    <vue-marquee :show-progress="false" direction="left" class="marquee-text">
      <vue-marquee-slide v-for="i in 15" :key="i">
        <span style="white-space: nowrap; padding-left: 40px; padding-right: 40px">{{$t('lending.text-marquee.text')}}</span>
      </vue-marquee-slide>
    </vue-marquee>
    <div class="container">
      <hr class="mt-1">
    </div>
  </section>
</template>

<script>
export default {
  name: "text-marquee"
}
</script>

<style scoped>
.marquee-text {
  text-transform: uppercase;
  height: 75px;
  font-size: 48px;
  color: white;
  font-weight: 600;
  text-shadow: 0px 0px 1px black
}

@media screen and (max-width: 768px) {
  .marquee-text {
    font-size: 32px;
    height: 48px;
  }
}

hr {
  margin: 0
}
</style>
