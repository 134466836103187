<template>
  <b-container class="p-0 container-px-lg" tag="section" >
    <div id="earn">
    <q-header level="2" sweep>
      <!-- Зарабатывайте <br class="mobile-word-break">на майнинге криптовалюты -->
      <i18n path="lending.earn-mining.header" tag="span">
        <br class="mobile-word-break">
      </i18n>
      <br>
      <b>{{$t('lending.earn-mining.header-a')}}</b><br class="mobile-word-break">{{$t('lending.earn-mining.header-b')}}<span class="position-relative"><b>{{$t('lending.earn-mining.header-c')}}</b><img
        alt="" class="lines position-absolute" src="@/assets/images/site/icons/grebeshki_bottom.svg"></span>
    </q-header>

    <div class="px-2 px-md-0">
      <earn-options-selector v-model="selectedEarnOption" class="mt-3"/>
    </div>

    <div class="mt-2 mt-lg-3">
      <transition-group class="position-relative" name="earn-option" tag="div">
        <!--        Buy contract option -->
        <earn-option-template v-if="getEarnOption === 0" key="1">
          <template #features>
            <earn-feature class="col-12 col-lg-4 mb-1 mb-lg-0">
              <span v-html="$t('lending.earn-mining.earn-feature-1')"> 
              </span>
              <!-- Возможность начать майнить <b>с&nbsp;любой суммы</b> -->
            </earn-feature>
            <earn-feature class="col-12 col-lg-4 mb-1 mb-lg-0">
              <!-- Сервисное обслуживание <b>включено в контракт</b> -->
              <span v-html="$t('lending.earn-mining.earn-feature-2')"> 
              </span>
            </earn-feature>
            <earn-feature class="col-12 col-lg-4 mb-1 mb-lg-0">
              <!-- После завершения контракта оборудование остается &shy;<b>в&nbsp;собственности Qubity</b> -->
              <span v-html="$t('lending.earn-mining.earn-feature-3')"> 
              </span>
            </earn-feature>
          </template>

          <template #how-it-works>
            <div class="col-12 col-lg-3 mb-1 mb-lg-0">
              <earn-how-it-work-card :icon="percent">
                <!-- Вы покупаете контракт на аренду части нашей вычислительной мощности <b>с комиссией 0%</b> -->
                <span v-html="$t('lending.earn-mining.earn-how-it-work-card-1')"> 
                </span>
              </earn-how-it-work-card>
            </div>
            <div class="col-12 col-lg-3 mb-1 mb-lg-0">
              <earn-how-it-work-card :icon="star">
                <!-- Ежедневно получаете прибыль с контрактов и отслеживаете заработок в личном кабинете -->
                <span v-html="$t('lending.earn-mining.earn-how-it-work-card-2')"> 
                </span>
              </earn-how-it-work-card>
            </div>
            <div class="col-12 col-lg-3 mb-1 mb-lg-0">
              <earn-how-it-work-card :icon="arrow">
                <!-- Переводите любую сумму на криптовалютный кошелек с моментальным выводом -->
                <span v-html="$t('lending.earn-mining.earn-how-it-work-card-3')"> 
                </span>
              </earn-how-it-work-card>
            </div>
            <div class="col-12 col-lg-3 mb-1 mb-lg-0">
              <earn-how-it-work-card :icon="smile">
                <!-- Мы обновляем и поддерживаем работу оборудования 24/7 -->
                <span v-html="$t('lending.earn-mining.earn-how-it-work-card-4')"> 
                </span>
              </earn-how-it-work-card>
            </div>
          </template>

          <template #content>
            <q-header :centered="false" color="var(--text-primary-color)" level="2" size="l">
              <b>
                <span v-html="$t('lending.earn-mining.profit-text')">
                </span>
                <span class="position-relative zindex-1">
                  {{$t('lending.earn-mining.profit-text-br')}}
                  <img alt="" class="stroke position-absolute" src="@/assets/images/site/ellipse.svg">
                </span>
              </b>
            </q-header>

            <earn-option-calculator class="mt-2"/>
          </template>
        </earn-option-template>
        <!--        Buy contract option END-->

        <!--        Buy miner option -->
        <earn-option-template v-else key="2" bg-color="var(--text-primary-color)" content-bg-color="#F9FBFF">
          <template #header>
            <q-header color="white" level="2" size="l">
              <span v-html="$t('lending.earn-mining.earn-option-template-bm')"> 
                <!-- <b>Создаем майнинг-бизнес «под ключ»</b> и управляем им
                <br>
                за Вас с <b>круглосуточным обслуживанием и охраной</b> -->
              </span>
            </q-header>
          </template>

          <template #features>
            <earn-feature class="col-12 col-lg-4 mb-1 mb-lg-0">
              <span v-html="$t('lending.earn-mining.earn-feature-bm-1')"> 
              </span>
            </earn-feature>
            <earn-feature class="col-12 col-lg-4 mb-1 mb-lg-0">
              <span v-html="$t('lending.earn-mining.earn-feature-bm-2')"> 
              </span>
            </earn-feature>
            <earn-feature class="col-12 col-lg-4 mb-1 mb-lg-0">
              <span v-html="$t('lending.earn-mining.earn-feature-bm-3')"> 
              </span>
            </earn-feature>
          </template>

          <template #how-it-works>
            <div class="col-12 col-lg-3 mb-1 mb-lg-0">
              <earn-how-it-work-card :icon="percent">
                <!-- Выберите один или несколько майнеров из каталога, <b>заключите контракт на 3 года</b> -->
                <span v-html="$t('lending.earn-mining.earn-how-it-work-card-bm-1')"> 
                </span>
              </earn-how-it-work-card>
            </div>
            <div class="col-12 col-lg-3 mb-1 mb-lg-0">
              <earn-how-it-work-card :icon="star">
                <span v-html="$t('lending.earn-mining.earn-how-it-work-card-bm-2')"> 
                </span>
              </earn-how-it-work-card>
            </div>
            <div class="col-12 col-lg-3 mb-1 mb-lg-0">
              <earn-how-it-work-card :icon="arrow">
                 <span v-html="$t('lending.earn-mining.earn-how-it-work-card-bm-3')"> 
                </span>
                <!-- Ежедневно получаете прибыль с оборудования <b>за вычетом 5% за сервисное обслуживание</b> -->
              </earn-how-it-work-card>
            </div>
            <div class="col-12 col-lg-3 mb-1 mb-lg-0">
              <earn-how-it-work-card :icon="smile">
                <span v-html="$t('lending.earn-mining.earn-how-it-work-card-bm-4')"> 
                </span>
                <!-- По окончании контракта можете <b>забрать оборудование или продлить контракт</b> -->
              </earn-how-it-work-card>
            </div>
          </template>

          <template #content>
            <b-row class="px-1 justify-content-between">
              <b-col>
                <q-header :centered="false" color="var(--text-primary-color)" level="1" size="xl">
                  <i18n path="lending.earn-mining.select-miner" tag="b">
                    <br>
                  </i18n>
                </q-header>
              </b-col>
              <b-col class="d-flex justify-content-end flex-column my-auto">
                <div v-b-modal:consultation class="text-right" to="/"><u>{{$t('lending.earn-mining.help-choosing')}}</u></div>
                <b-col class="d-none d-md-flex justify-content-end mt-1 px-0" cols="12">
                  <p class="mr-1 my-auto">{{$t('lending.earn-mining.filter')}} </p>
                  <div>
                    <b-dropdown class="sorting-dropdown" size="sm" :text="minersSortOption.text" variant="outline-primary">
                      <b-dropdown-item @click="minersSortOption = {text: $t('lending.earn-mining.price-desc'), sorting: {column: 'price_amount', sort: 'DESC'}}; loadMiners()">{{$t('lending.earn-mining.price-desc')}}</b-dropdown-item>
                      <b-dropdown-item @click="minersSortOption = {text: $t('lending.earn-mining.price-inc'), sorting: {column: 'price_amount', sort: 'ASC'}}; loadMiners()">{{$t('lending.earn-mining.price-inc')}}</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-col>
              </b-col>
            </b-row>
            <b-col class="d-md-none d-flex justify-content-between flex-row mt-1 px-1" cols="12">
              <p class="mr-1 my-auto">{{$t('lending.earn-mining.filter')}} </p>
              <div>
                <b-dropdown class="sorting-dropdown" size="sm" :text="minersSortOption.text" variant="outline-primary">
                  <b-dropdown-item @click="minersSortOption = {text: $t('lending.earn-mining.price-desc'), sorting: {column: 'price_amount', sort: 'DESC'}}; loadMiners()">{{$t('lending.earn-mining.price-desc')}}</b-dropdown-item>
                  <b-dropdown-item @click="minersSortOption = {text: $t('lending.earn-mining.price-inc'), sorting: {column: 'price_amount', sort: 'ASC'}}; loadMiners()">{{$t('lending.earn-mining.price-inc')}}</b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
            <EarnOptionMinerCard
                v-for="(miner, index) in miners"
                :key="index"
                :diff_percent="diff_percent"
                :miner="miner"
                class="mt-1"
            />
            <div class="pagination mt-2 mx-auto" v-if="minersTotalPages > 0">
              <div v-for="(page, index) in pagesRange" :key="index" :class="minersPage === page + 1 && 'active-page'"
                   class="pagination-page-btn" @click="setPage(page + 1)"><span class="m-auto">{{
                  page + 1
                }}</span></div>
            </div>
          </template>
        </earn-option-template>
        <!--        Buy miner option END-->
      </transition-group>
    </div>
    </div>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";

import EarnOptionsSelector from "@/views/Main/main-design-v2/views/home/components/earn-section/EarnOptionsSelector";
import EarnOptionTemplate from "@/views/Main/main-design-v2/views/home/components/earn-section/EarnOptionTemplate";
import EarnFeature from "@/views/Main/main-design-v2/views/home/components/earn-section/EarnFeature"
import EarnHowItWorkCard from "@/views/Main/main-design-v2/views/home/components/earn-section/EarnHowItWorkCard";
import EarnOptionMinerCard from "@/views/Main/main-design-v2/views/home/components/earn-section/EarnOptionMinerCard"
import EarnOptionCalculator from "@/views/Main/main-design-v2/views/home/components/earn-section/calculator/calculator"

import smile from "@/assets/images/icons/smile.svg"
import arrow from "@/assets/images/icons/arrow-outline.svg"
import star from "@/assets/images/icons/star-outline.svg"
import percent from "@/assets/images/icons/percent.svg"
import $request from "@/modules/request/";

export default {
  name: "earn-mining",
  components: {
    EarnHowItWorkCard,
    EarnOptionTemplate,
    EarnOptionsSelector,
    EarnFeature,
    EarnOptionMinerCard,
    EarnOptionCalculator
  },
  data() {
    return {
      minersSortOption: {text: this.$t('lending.earn-mining.price-desc'), sorting: {column: 'price_amount', sort: 'DESC'}},
      smile,
      arrow,
      star,
      percent,
      selectedEarnOption: 0,
      miners: [],
      minersTotalPages: 0,
      minersPage: 1,
      diff_percent: 0,
      months_count: 12
    }
  },
  computed: {
    ...mapGetters(["getEarnOption"]),
    pagesRange: function () {
      return Array.from(Array(this.minersTotalPages).keys())
    }
  },
  methods: {
    loadMiners(page=1) {
      this.minersPage = page
      $request.get("miners.list", {orderby: this.minersSortOption.sorting, limit: 10, page: page}).then(rsp => {
        this.minersTotalPages = rsp.last_page
        this.miners = rsp.items;
      });
    },
    setPage(n) {
      this.minersPage = n
      this.loadMiners(n)
    },
    getPercentForPeriod() {
        if( this.months_count < 1 ) {
          this.months_count = 1;
        } else if( this.months_count > 24 ) {
          this.months_count = 24;
        }

        this.months_count = this.months_count.toFixed(0)
        
        this.$request.get("utils.getDiffHistory", {
            period: `${this.months_count}months`
        }).then(result => {
            this.diff_percent = parseFloat((result / this.months_count).toFixed(2));
        });
    },
  },
  created() {
    this.loadMiners()
    this.getPercentForPeriod()
  }
}
</script>

<style>
.earn-option {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.earn-option-move {
  transition: all 600ms ease-in-out 50ms;
}

/* appearing */
.earn-option-enter-active {
  transition: all 400ms ease-out;
}

/* disappearing */
.earn-option-leave-active {
  transition: all 200ms ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disappear to */
.earn-option-enter,
.earn-option-leave-to {
  opacity: 0;
}

.sorting-dropdown button {
  border-color: rgba(26, 86, 255, 0.1) !important;
  padding: 10px 20px;
}

.stroke {
  z-index: -1;
  width: 110%;
  left: -4%;
}

.lines {
  top: 10px;
}

.pagination-page-btn {
  aspect-ratio: 1;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid rgba(169, 169, 169, 0.3);
  display: flex;
  margin-left: 4px;
  transition: all .3s ease;
  cursor: pointer;
}

.active-page {
  border-color: var(--theme-accent-light) !important;
  color: var(--theme-accent-light) !important;
}
</style>
