<template>
  <div class="d-flex">
    <img src="@/assets/images/icons/coin.svg" class="mr-2" alt="">
    <div class="feature-text d-flex">
      <span class="my-auto"><slot></slot></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EarnFeature"
}
</script>

<style scoped>
.feature-text {
  color: white;
  font-size: 18px;
}

img {
  width: 48px;
}
</style>
