<template>
  <section class="faq container container-px-lg py-4" id="faq">
    <q-header :centered="false" class="mb-3">
      <b>
        {{$t('lending.faq.header-a')}}
        <span class="faq-header position-relative">{{$t('lending.faq.header-b')}}<img alt="" src="@/@core/assets/images/btn_sweep.svg"></span>
        <br>
        {{$t('lending.faq.header-c')}}
      </b>
    </q-header>

    <div class="faq-items">
      <faq-item data-animated>
        <template #question>
          {{$t('lending.faq.que-1')}}
        </template>
          {{$t('lending.faq.ans-1')}}
      </faq-item>

      <faq-item data-animated>
        <template #question>
          {{$t('lending.faq.que-2')}}
        </template>
          {{$t('lending.faq.ans-2')}}
      </faq-item>

      <faq-item data-animated>
        <template #question>
          {{$t('lending.faq.que-3')}}
        </template>
          {{$t('lending.faq.ans-3')}}
      </faq-item>

        <faq-item data-animated>
        <template #question>
          {{$t('lending.faq.que-4')}} 
        </template>
          {{$t('lending.faq.ans-4')}}
      </faq-item>

      <faq-item data-animated>
        <template #question>
          {{$t('lending.faq.que-5')}}
        </template>
          {{$t('lending.faq.ans-5')}}
      </faq-item>

      <faq-item data-animated>
        <template #question>
          {{$t('lending.faq.que-6')}}
        </template>
          {{$t('lending.faq.ans-6')}}
      </faq-item>
    </div>

    <div class="d-flex justify-content-center mt-3">
      <q-btn variant="secondary" to="/knowledge">
          {{$t('lending.faq.more')}} 
      </q-btn>
    </div>
  </section>
</template>

<script>
import FaqItem from "@/views/Main/main-design-v2/views/home/components/FaqItem";
export default {
  name: "faq",
  components: {FaqItem}
}
</script>

<style scoped lang="scss">
.faq-header {
  img {
    position: absolute;
    transform: rotate(120deg);
    top: 5px;
  }
}
</style>
