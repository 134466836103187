<template>
    <section class="gradient-section">
      <div class="background-text w-100" :style="{
        'background-image': $i18n.locale === 'ru' ? 
            `url(${require('@/assets/images/site/slider-background.svg')})` : 
            `url(${require('@/assets/images/site/slider-background-en.svg')})`
      }">
        <b-container class="py-5 container-px-lg">
          <b-row class="justify-content-between">
            <b-col cols="12" md="7" xl="6">
              <q-header :centered="false" color="#fff" size="l" level="1">
                <b>Qubity <span v-if="$i18n.locale == 'ru'">—</span></b> {{$t('lending.command.header-a')}}
                <span class="position-relative zindex-2">
                  <img
                      alt=""
                      class="position-absolute w-100 zindex-minus-1 text-ellipse"
                      src="@/assets/images/site/icons/Ellipse 1.svg"
                      style="top:18%"
                  >{{$t('lending.command.header-b')}}
              </span>
                {{$t('lending.command.header-c')}} <b>{{$t('lending.command.header-d')}}</b>
              </q-header>
            </b-col>
            <b-col cols="12" md="5" lg="4" class="my-auto">
              <q-header :centered="false" color="#fff" size="s" level="2">
                {{$t('lending.command.about')}}
              </q-header>
            </b-col>
          </b-row>
          <div class="slider-container">
            <q-carousel control-class="slider-control">
              <q-slide v-for="(member, index) in members" :key="index">
                <command-member-card
                    :img="member.img"
                    :name="member.name"
                    :post="member.post"
                />
              </q-slide>
            </q-carousel>
          </div>
          <b-row>
            <b-col>
              <hr class="w-100 my-2" style="border-top: 1px solid rgba(255, 255, 255, 0.18);">
            </b-col>
          </b-row>
          <b-row class=" flex-column">
            <b-col cols="12" sm="8" lg="6" xl="4" class="mx-auto">
              <q-btn block to="/vacancies">
                {{$t('lending.command.part-the-team')}}
              </q-btn>
              <q-header level="6" size="s" class="mt-2" color="var(--text-secondary-color)">
                <!-- <span style="font-size: 80%">{{$t('lending.command.available-vacancy')}}<b>{{vacancies.dataSource.items.length}} {{getVacancyHolder}}</b></span> -->
                 <i18n path="lending.command.available-vacancy" style="font-size: 80%" tag="span">
                 <b v-if="$i18n.locale == 'ru'"></b>
                 <b v-else>{{vacancies.dataSource.items.length}} {{getVacancyHolder}}</b>

                 <b v-if="$i18n.locale == 'ru'">{{vacancies.dataSource.items.length}} {{getVacancyHolder}}</b>
                 <b v-else></b>
                </i18n>
              </q-header>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </section>
</template>

<script>
import QSlide from "@/views/Main/main-design-v2/components/QSlide";
import QCarousel from "@/views/Main/main-design-v2/components/QCarousel";
import CommandMemberCard from "@/views/Main/main-design-v2/views/home/components/CommandMemberCard";

import A from "@/assets/images/site/members/Arseniy Shulgin.png";
import B from "@/assets/images/site/members/Pavel Ukhanov.png";
import C from "@/assets/images/site/members/Timur Nurtdinov.png";
import D from "@/assets/images/site/members/Ekaterina Glukhova.png";
import E from "@/assets/images/site/members/Tatiana Turakhonova.png";
import F from "@/assets/images/site/members/Maksim Ostroumov.png";
import G from "@/assets/images/site/members/Nikolai Popov.png";
import H from "@/assets/images/site/members/Igor Nesterov.png";
import I from "@/assets/images/site/members/Adam Tambiev.png";
import J from "@/assets/images/site/members/Anna Orlova.png";

import vacancies from "@/modules/contents/vacancies";
import Utils from "@/modules/utils/"

export default {
  name: "command",
  components: {QCarousel, QSlide, CommandMemberCard},
  data() {
    return {
      vacancies,
      members: [
        {img: A, name: 'lending.command.ceo', post: "CEO, Founder"},
        {img: C, name: 'lending.command.cfo', post: "Chief Financial Officer"},
        {img: F, name: 'lending.command.coo', post: "Chief Operations Officer"},
        {img: B, name: 'lending.command.cto', post: "Chief Technical Officer"},
        {img: D, name: 'lending.command.hr', post: "HR Department"},
        {img: J, name: 'lending.command.cmo', post: "Chief Marketing Officer"},
        {img: E, name: 'lending.command.cm', post: "Community Manager"},
        {img: G, name: 'lending.command.hpd', post: "Head of the Purchasing Department"},
        {img: H, name: 'lending.command.lsm', post: "Lead Sales Manager"},
        {img: I, name: 'lending.command.hsd', post: "Head of Sales Department"},
      ],
      A, B, C, D, E, F, G, H, I, J
    }
  },
  computed: {
    getVacancyHolder() {
      let decl =  ['вакансия', 'вакансии', 'вакансий'];
      if (this.$i18n.locale == 'en') decl = ['vacancy', 'vacancies', 'vacancies'];

      return Utils.declOfNum(vacancies.dataSource.items.length, decl);
    },
  },
  created() {
    if (vacancies.dataSource.items.length === 0) {
      vacancies.get()
    }
  }
}
</script>

<style lang="scss">
.slider-container {
  .slider-control {
    filter: invert(100%);
    margin-right: 30px;
  }

  .dots {
    padding-left: 30px;
    margin-top: auto;
    margin-bottom: auto;

    .active-dots {
      margin-left: 30px;
      background-color: #fff !important;
    }

    .dot {
      background-color: #fff !important;
      opacity: .1;
    }
  }
}
</style>

<style scoped lang="scss">
.gradient-section {
  background: linear-gradient(180deg, #181818 0%, #262CB8 116.45%);
}

.background-text {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -25vh;
  background-attachment: fixed;
}

.gradient {
  background: linear-gradient(180deg, #181818 0%, #262CB800 116.45%);
  height: 20px;
}
</style>
