<template>
  <div class="q-slide__wrapper">
    <div class="q-slide">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "QSlide"
}
</script>

<style scoped lang="scss">
@import "styles/q-carousel-slider";
</style>
