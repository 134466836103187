<template>
  <div style="display: contents">
    <transition name="slide-to-right" mode="out-in" appear>
      <div v-if="showPopup"
           class="d-none d-lg-block position-fixed video-popup"
           :class="isCartNotEmpty && 'popup-to-up'"
      >
        <div class="close-popup position-absolute" @click="hidePopup()">
          <img :src="require('@/assets/images/icons/close-popup.svg')" alt="">
        </div>
        <div v-if="!showVideo" class="video-loader position-relative">
          <div class="overflow position-absolute">
            <div class="content text-center d-flex flex-column h-100">
              <div>{{$t('lending.video-popup.title-a')}}</div>
              <img :src="require('@/assets/images/icons/play-video.svg')" alt="" class="my-auto play-video mx-auto"
                   width="56"
                   @click="showVideo = true">
              <div>{{$t('lending.video-popup.title-b')}}</div>
            </div>
          </div>
        </div>
        <iframe v-else allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen frameborder="0"
                height="100%" :src="$i18n.locale === 'en' ? 'https://www.youtube.com/embed/d8faTAIGUkM?autoplay=1' : 'https://www.youtube.com/embed/ePZLCVylmyY?autoplay=1'"
                title="Qubity.io"
                width="100%"></iframe>
      </div>
    </transition>
    <div v-if="!showPopup" :class="isCartNotEmpty && 'popup-to-up'" class="show-video-flag d-none d-lg-block">
      <img :src="require('@/assets/images/icons/play-video.svg')" alt="" class="my-auto play-video mx-auto" width="50" @click="showPopup = true">
    </div>
  </div>
</template>

<script>
import miners from "@/modules/contents/miners";

export default {
  name: "VideoPopup",
  data() {
    return {
      miners,
      showVideo: false,
      showPopup: true,
    }
  },
  computed: {
    isCartNotEmpty() {
      return Object.keys(miners.cart).length >= 1
    }
  },
  methods: {
    hidePopup() {
      this.showPopup = false
    }
  }
}
</script>

<style>
.slide-to-right-enter-active .slide-to-left-leave-active {
  transition: all 0.3s ease;
}

.slide-to-right-enter-from,
.slide-to-right-leave-to {
  transform: translateX(350px);
}
</style>

<style lang="scss" scoped>
.video-popup {
  bottom: 20px;
  right: 16px;
  width: 280px;
  height: 200px;
  z-index: 9;
  overflow: hidden;
  border-radius: 32px;
  transition: all .3s ease;
  background: var(--theme-accent-dark);
  box-shadow: -14px 43px 53px rgba(81, 78, 120, 0.04);

  .close-popup {
    top: 16px;
    right: 16px;
    background: rgba(255, 255, 255, .5);
    display: flex;
    padding: 5px;
    border-radius: 100px;
    transition: all .3s ease;
    cursor: pointer;
    z-index: 10;

    &:hover {
      background: rgba(255, 255, 255, .25);
    }
  }
}

.video-loader {
  width: 100%;
  height: 100%;
  background: url("~@/assets/images/video-back.png");
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .overflow {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
  }

  .content {
    padding: 30px;
    margin: 0 !important;
    color: white;
  }

  .play-video {
    transition: all .3s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.show-video-flag {
  position: fixed;
  bottom: 80px;
  right: 16px;
  border-radius: 100px;
  background: var(--theme-accent-light);
  cursor: pointer;
  z-index: 9;
  transition: all .3s ease;
}

.popup-to-up {
  bottom: 100px;
}
</style>
