<template>
  <div class="position-relative section pb-5">
    <b-container class="mt-5 d-flex flex-xl-row flex-column container-pl-lg pr-0">
      <b-col class="pl-0 container-pr-lg my-auto" cols="12" xl="6">
        <span class="question">{{$t('lending.guarantee-earnings.question')}}</span>
        <q-header class="text-left mt-2" level="1" v-bind:centered="false">
          <span v-html="$t('lending.guarantee-earnings.header-a')"> 
          </span>
          <span
            class="position-relative">{{$t('lending.guarantee-earnings.header-b')}}<img alt="" class="lines position-absolute"
                                                   src="@/assets/images/site/icons/grebeshki_bottom.svg"></span>
        </q-header>
        <b-row class="mt-5 text-blocks h-shade">
          <b-col class="v-shade" cols="12" sm="6"  data-animated="animate__fadeInLeft">
            <h3>{{$t('lending.guarantee-earnings.h3-shade-1')}}</h3>
            <p>{{$t('lending.guarantee-earnings.p-shade-1')}}</p>
            <hr class="d-block d-sm-none my-1"/>
          </b-col>
          <b-col cols="12" sm="6"  data-animated="animate__fadeInRight">
            <h3>{{$t('lending.guarantee-earnings.h3-shade-2')}}</h3>
            <p>{{$t('lending.guarantee-earnings.p-shade-2')}}</p>
            <hr class="d-block d-sm-none my-1"/>
          </b-col>
        </b-row>
        <b-row class="text-blocks">
          <b-col class="pt-0 pt-sm-2 v-shade" cols="12" sm="6"  data-animated="animate__fadeInLeft">
             <h3>{{$t('lending.guarantee-earnings.h3-shade-3')}}</h3>
            <p>{{$t('lending.guarantee-earnings.p-shade-3')}}</p>
            <hr class="d-block d-sm-none my-1"/>
          </b-col>
          <b-col class="pt-0 pt-sm-2 " cols="12" sm="6" data-animated="animate__fadeInRight">
            <h3>{{$t('lending.guarantee-earnings.h3-shade-4')}}</h3>
            <p>{{$t('lending.guarantee-earnings.p-shade-4')}}</p>
          </b-col>
        </b-row>
        <b-row class="mt-3 mx-auto">
          <b-col class="pl-0 text-center text-sm-left" cols="12" sm="6" data-animated="animate__backInLeft">
            <q-btn id="start-earning" to="/register"><span style="font-size: 85%">{{$t('lending.btns.start-earning')}}</span>
            </q-btn>
          </b-col>
          <b-col class="my-2 my-sm-auto d-flex" cols="12" sm="6">
            <b-btn v-b-modal:video
                   class="d-inline-flex mx-sm-0 mx-auto p-0 justify-content-start text-left watch-reviews">
              <img alt="" src="@/assets/images/site/icons/play.svg">
              <q-header :centered="false" class="ml-1 my-auto" level="5" size="2" data-animated>
                {{$t('lending.guarantee-earnings.watch-video')}}
              </q-header>
            </b-btn>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="px-0 mr-0 d-flex justify-content-end align-items-center position-relative" cols="12" xl="6">
        <img v-if="$i18n.locale == 'en'" alt="" class="d-lg-none devices h-100 w-100 mr-0" src="@/assets/images/site/devices_m_en.png">
        <img v-else alt="" class="d-lg-none devices h-100 w-100 mr-0" src="@/assets/images/site/devices_m.png">

        <img v-if="$i18n.locale == 'en'" alt="" class="w-75 mr-0 position-absolute text-with-arrow"
             src="@/assets/images/site/platform_available_arrow_en.png">
        <img v-else alt="" class="w-75 mr-0 position-absolute text-with-arrow"
             src="@/assets/images/site/platform_available_arrow.png">

        <img v-if="$i18n.locale == 'en'" alt="" class="big-devices d-none d-lg-block w-100 mr-0"
             src="@/assets/images/site/platform_available_full_en.png">
        <img v-else alt="" class="big-devices d-none d-lg-block w-100 mr-0"
             src="@/assets/images/site/platform_available_full.png">
      </b-col>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "guarantee_earnings"
}
</script>

<style>
.watch-reviews, .watch-reviews:focus, .watch-reviews:active {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}
</style>

<style lang="scss" scoped>

.question {
  color: var(--theme-accent-light);
}

.lines {
  width: 30px;
  height: 30px;
  top: 50%;
}

.text-blocks {
  h3 {
    font-weight: bolder;
    color: var(--text-primary-color);
    font-size: 22px;
  }

  p {
    font-size: 20px;
  }
}

.v-shade {
  border-right: 1px solid rgba(0, 0, 0, .1);
}

.h-shade {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.devices {
  right: 0;
  top: -10%;
  width: 40%;
}

.text-with-arrow {
  left: 0;
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
  .text-with-arrow {
    top: 10px;
  }

  .devices {
    top: 10%;
    width: 40%;
  }
}

@media screen and (max-width: 1200px) {
  .big-devices {
    margin-top: 100px;
  }

  .text-with-arrow {
    top: 0px;
  }
}

@media screen and (min-width: 1440px) {
  .devices {
    top: 10%;
    width: 35%;
  }
}

@media screen and (min-width: 1440px) {
  .devices {
    top: 2%;
    height: 816px;
    width: auto;
  }
}

@media screen and (max-width: 576px) {
  .h-shade, .v-shade {
    border-bottom: none;
    border-right: none;
  }
}

.fit-contain {
  object-fit: contain;
}
</style>

<style lang="scss">
#start-earning:not(.btn) {
  width: 100%;

  button#start-earning {
    padding-left: 20px !important;
    padding-right: 20px !important;
    width: 100%;
    display: inline-block;
  }
}
</style>
