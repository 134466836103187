<template>
  <b-container>
    <b-row class="justify-content-between">
      <b-col cols="12" md="8" xl="6">
        <slot>
        </slot>
      </b-col>
      <b-col class="animate__delay-1s special-offer position-relative d-flex mt-2 mt-md-0" data-animated="animate__pulse" cols="10" md="3" offset="1">
        <div class="m-auto py-1 py-md-1 d-flex flex-column">
          <q-header class="mb-0" color="var(--theme-accent-light)" level="5" size="">
            {{$t('lending.start-earning.special-text')}}
          </q-header>
          <countdown :end-time="new Date(offers.until * 1000).getTime()">
            <q-header slot="process" slot-scope="countdown" class="countdown" color="var(--theme-accent-light)"
                      level="4"
                      size="l">
              {{ `${countdown.timeObj.h}:${countdown.timeObj.m}:${countdown.timeObj.s}` }}
            </q-header>
          </countdown>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2 my-5">
      <StartEarningTariffCard
          v-for="(offer, index) in cloudOffers"
          :key="index"
          :item="offer"
      />
    </b-row>
  </b-container>
</template>

<script>
import StartEarningTariffCard from "@/views/Main/main-design-v2/views/home/components/StartEarningTariffCard";
import offers from "@/modules/contents/offers"

export default {
  name: "start-earning",
  components: {StartEarningTariffCard},
  data() {
    return {
      offers,
    }
  },
  computed: {
    cloudOffers() {
      if (!offers.items.length) return [];

      let clouds = offers.items.filter(l => (l.type === 1));

      if (clouds.length > 3) return clouds.slice(0, 3)
      else return clouds
    }
  },
  created() {
    if (!offers.items.length) {
      offers.get()
    }
  }
}
</script>

<style lang="scss" scoped>
.special-offer {
  border: 1px solid #4D68F7;
  box-sizing: border-box;
  transform: rotate(9deg);
  border-radius: 55%;
  left: -16px;
}

@media screen and (max-width: 768px) {
  .special-offer {
    transform: rotate(0);
    left: 0;
    width: 80%;
  }

  .lines {
    display: none;
  }
}
</style>

<style lang="scss">
.countdown {
  h4 {
    font-weight: 600 !important;
    font-size: 32px !important;
  }
}
</style>
