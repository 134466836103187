<template>
  <section class="pb-5">
    <div class="ceo-quote container container-px-lg">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col-12 d-none d-md-flex align-items-end col-md-2">
          <img alt="" class="w-100 ceo-avatar" src="~@/assets/images/site/bubble/q-author-photo.jpeg">
        </div>
        <div class="col-12 col-md-10">
          <div data-animated="animate__fadeInDown" class="quote position-relative">
            <div class="d-flex flex-column">
              <span>{{$t('lending.ceo-quote.text')}}<b>{{$t('lending.ceo-quote.text-b')}}</b></span>
              <q-btn id="start-mining" to="/register" class="zindex-1 start-mining ml-auto mt-1" variant="secondary">
                {{$t('lending.btns.start-mining')}}
              </q-btn>
            </div>
            <img alt="" class="tail-ceo position-absolute d-none d-md-block" src="@/assets/images/site/tail_ceo.svg">
            <img alt="" class="tail-ceo-sm position-absolute d-md-none" src="@/assets/images/site/tail_ceo_sm.svg">
          </div>
          <div class="quote-author mt-2">
            <div class="d-flex d-md-block">
              <div class="col-2 pr-0 d-flex d-md-none align-items-end">
                <img alt="" class="w-100 ceo-avatar" src="~@/assets/images/site/bubble/q-author-photo.jpeg">
              </div>
              <div class="col-10 col-md-12 mt-auto">
                <div><b> {{$t('lending.command.ceo')}}</b></div>
                <div><span class="text-muted">CEO Qubity</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ceo-quote"
}
</script>

<style lang="scss" scoped>
section {
  background: #f9fbff;
}

.quote {
  background: var(--theme-accent-dark);
  padding: 26px 60px;
  color: white;
  border-radius: 32px;

  span {
    z-index: 1;
    font-size: 28px;
  }

  @media screen and (max-width: 992px) {
    span {
      font-size: 20px;
    }

    & {
      padding: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    span {
      font-size: 18px;
    }

    & {
      padding: 16px;
    }
  }

  .tail-ceo {
    bottom: -25px;
    left: 0;
    z-index: 0;
  }

  .tail-ceo-sm {
    bottom: -15px;
    left: 20%;
    z-index: 0;
  }
}

.ceo-avatar {
  border-radius: 100px;
  aspect-ratio: 1;
  object-fit: cover;
}


@media screen and (min-width: 768px) {
  .quote-author {
    padding-left: 60px;
  }
}
</style>

<style lang="scss">
#start-mining {
  button, a {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 768px) {
  #start-mining {
    button, a {
      font-size: 14px !important;
      padding: 12px 20px!important;
    }
  }
}
</style>
