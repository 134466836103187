<template>
  <div class="p-1">
    <img :src="img" alt="" class="w-100">
    <b-row class="p-2 description justify-content-around flex-column">
      <q-header level="3" color="#fff" size="m">
        <span style="font-size: 80%">{{$t(name)}}</span>
      </q-header>
      <q-header level="3" color="var(--text-secondary-color)" size="s">
        <span style="font-size: 90%">{{post}}</span>
      </q-header>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "CommandMemberCard",
  props: {
    img: {type: String, default: "@/"},
    name: {type: String, default: "Арсений Шульгин"},
    post: {type: String, default: "CEO, Founder"}
  }
}
</script>

<style scoped lang="scss">
.description {
  background-color: #373A82;
  border-radius: 24px;
}
</style>
