<template>
  <div class="md-calculator-result">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-12">
        <p class="expected-label" style="margin-bottom: 6px;"> {{$t('calculator.expected-income-in')}}
          <b-link class="curr" @click="changeCurrency"> {{ User.currency }}</b-link>
        </p>
        <div class="d-flex">
          <p class="expected mt-1">
            {{ (summary - fee - service).formatMoney(User.currency === "BTC" ? 4 : 0, ' ', ' ') }}
            <span class="">{{ User.getSymbol() }}</span>
          </p>
          <div class="d-flex percents-expected">
            <span v-if="percent > 0" class="" style="color: #3ED085; padding-left: 5px; ">
              +{{ percent }}%
            </span>
            <span v-else class="" style="color: #FF6F71; padding-left: 5px; ">
              {{ percent }}%
            </span>
            <feather-icon
                v-b-popover.hover.top="$t('popovers.income')"
                class="cursor-pointer info-hover text-muted"
                icon="AlertCircleIcon"
                style="margin: 4px 3px 3px;"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-12 d-flex">
        <div class="mt-auto">
          <p class="power-label mt-1" style="margin-bottom: 0;">{{$t('calculator.contract-capacity')}}</p>
          <div class="d-flex">
            <p class="mb-0">
              <span style="font-size: 24px; font-weight: 500;">{{ total_ths.formatMoney(2, ' ', ' ') }}</span>
              <span style="font-size: 18px;"> TH/s</span>
              <feather-icon
                  v-b-popover.hover.top="$t('popovers.ths')"
                  class="cursor-pointer info-hover text-muted"
                  icon="AlertCircleIcon"
                  style="margin: 0px 0px 3px 3px;"
              />
            </p>
          </div>
        </div>
      </div>
    </div>

    <q-btn block
           class="mt-3"
           variant="primary"
           @click="createContract"
    >
      {{$t('lending.btns.create-contract')}}
    </q-btn>

    <p class="text-center"
       style="font-size: 13px; color: var(--text-primary-color); line-height: 1.1; margin-top: 16px;">
        {{$t('calculator.conditions-of-profitability')}}
      <router-link style="text-decoration: underline; color: var(--text-primary-color)" to="/public-offer">
        {{$t('calculator.full-terms-offer')}}
      </router-link>
    </p>
  </div>
</template>

<script>

import User from "@/modules/user/"

export default {

  data() {
    return {
      User
    }
  },
  props: {

    total_ths: {
      type: Number,
      default: 0
    },
    current_price: {
      type: Number,
      default: 0
    },
    total_btc_price: {
      type: Number,
      default: 0
    },

    calcType: {
      type: Number,
      default: 0 // history
    },

    diff_history: {
      type: Number,
      default: 10,
    },

    btc_percent: {
      type: Number,
      default: 10
    },

    diff_percent: {
      type: Number,
      default: 0
    },

    period: {
      type: Number,
      default: 12
    },

    market_price_percent: {
      type: Number,
      default: 10
    }


  },
  methods: {
    changeCurrency(event) {
      event.stopPropagation();
      this.$bvModal.show("currency_modal")
      // this.$router.push(`${this.$route.path}?act=currency`);
    },

    createContract(args) {

      if (User.isAuth()) {
        this.$router.push({
          name: "user-contracts-create",
          query: {
            period: this.period,
            summ: this.investments
          }
        });
      } else {
        sessionStorage.setItem("after:auth", JSON.stringify({
          "type": "rent",
          "data": {
            period: this.period,
            summ: this.investments
          }
        }));

        this.$router.push("/authorization");
      }

    },

  },

   computed: {

    fee() {
        return (this.period_profit * 0.025) * this.market_price;
    },

    fee_btc() {
        return this.period_profit * 0.025;
    },
    
    service() {
        return (this.period_profit * 0.05) * this.market_price;
    },

    service_btc() {
        return this.period_profit * 0.05;
    },
    
    difficult() {
        let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
        let growing = temp * this.period;
        let diff = parseInt(this.$user.contracts.values.D) + growing;

        if( diff <= 10000000000000 ) {
            diff = 10000000000000;
        }
                        
        return parseInt(diff);
    },
    
    market_price() {
        // формула вычета сложно процента 
        if (User.currency === 'USD') {
            return this.$blockchain.tickers.values[User.currency].last * (Math.pow((1 + this.market_price_percent / 100), this.period))
        } else if  (User.currency === 'BTC') {
            return 1;
        } else {
            return this.$blockchain.tickers.values[User.currency].last;
        }
    },

    market_price_btc() {
        
        let temp = this.$blockchain.tickers.values['BTC'].last * ( this.market_price_percent / 100 );
        let growing = temp * this.period;
        
        return this.$blockchain.tickers.values['BTC'].last + growing;
        
    },

    period_profit() {
        // через средний рост сложности майнинга
        // return (86400 * 30 * this.period * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( ((this.difficult + parseInt(this.$user.contracts.values.D)) / 2 ) * Math.pow(2, 32) );

        let profit = 0;
        let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
        let diff = parseInt(this.$user.contracts.values.D);

        for (let i = 0; i < this.period; i++) {
            profit += (86400 * 30 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( diff * Math.pow(2, 32) );

            diff += temp;
        }

        return profit;
    },
    
    price_in_btc() {
        return this.current_price / this.period / this.$blockchain.tickers.values.USD.last;                
    },
    
    price() {
        return this.price_in_btc * this.$blockchain.tickers.values.USD.last;
    },
        
    summary() {
        //v2
        return (this.period_profit * this.market_price) - this.energy_payout;

        //v1
        // return (this.period_profit * this.market_price) - this.energy_payout * this.period;
    },

    summary_in_btc() {
        return this.period_profit - this.energy_payout_btc - this.fee_btc - this.service_btc;
    },
    
    investments() {
        return this.total_btc_price * this.$blockchain.tickers.values[User.currency].last; 
    },
    
    percent() {
        return (( (this.summary - this.fee - this.service - this.investments)  / this.investments) * 100).toFixed(2);
    },

    month_energy_payout_usd() {
        // за месяц
        return this.total_ths * ( this.$user.contracts.values.E * 24 * 30 );
    },
    
    // //v1
    // energy_payout() {
    //     if( User.currency === "BTC" ) {
    //         return this.energy_payout_btc; 
    //     }
        
    //     return this.energy_payout_btc * this.$blockchain.tickers.values[User.currency].last;
    // },

    energy_payout() {
        let energy_btc = 0;

        let month_energy_usd = this.month_energy_payout_usd
        let usd_rate = this.$blockchain.tickers.values.USD.last; 

        for (let i = 0; i < this.period; i++) {
            energy_btc += month_energy_usd / usd_rate;

            usd_rate = usd_rate * (1 + this.market_price_percent / 100);
        }

        if (User.currency === "BTC") {
            return energy_btc;
        } else if (User.currency === "USD") {
            return month_energy_usd * this.period;
        } else {
            return energy_btc * this.$blockchain.tickers.values[User.currency].last;
        }
    },

    energy_payout_btc() {
        let energy_btc = 0;

        let month_energy_usd = this.month_energy_payout_usd
        let usd_rate = this.$blockchain.tickers.values.USD.last; 

        for (let i = 0; i < this.period; i++) {
            energy_btc += month_energy_usd / usd_rate;

            usd_rate = usd_rate * (1 + this.market_price_percent / 100);
        }

        return energy_btc;
    }
    
},

  components: {},
  watch: {
    
  },
  mounted() {

  }

}

</script>

<style lang="scss" scoped>
.md-calculator-result {
  .power-label {
    font-size: 13px;
  }

  .expected {
    font-weight: 600;
    font-size: 40px;
    line-height: unset !important;
    margin-bottom: 0 !important;
  }

  .percents-expected {
    margin-top: 40px;
  }

  .expected-label {
    font-weight: bold;
    font-size: 18px;
  }

  .expected-label .curr {
    font-weight: normal !important;
  }
}
</style>
