<template>
  <div>
    <div :style="'background-color: ' + bgColor" class="earn-option-header">
      <slot name="header">
        <q-header color="white" level="2" size="l">
          <b>{{$t('lending.earn-mining.earn-option-template-a')}}</b>
          <br>
          {{$t('lending.earn-mining.earn-option-template-b')}}<b>{{$t('lending.earn-mining.earn-option-template-c')}}</b>
          <span class="text-muted">
            <feather-icon
                v-b-popover.hover.top="{customClass: 'light-popover', content:$t('popovers.power-equipment')}"
                class="cursor-pointer info-hover"
                icon="AlertCircleIcon"
                style="margin-left: 6px;"
            />
          </span>
        </q-header>
      </slot>

      <div class="row mt-2">
        <slot name="features">
        </slot>
      </div>

      <hr class="my-4" style="border-color: rgba(255, 255, 255, .2)">

      <q-header color="white" level="3" size="l">
        <b>{{$t('lending.earn-mining.how-does-it-work')}}</b>
      </q-header>

      <div class="how-it-works mt-2 row">
        <slot name="how-it-works"></slot>
      </div>

    </div>
    <div :style="'background-color: ' + contentBgColor" class="earn-option-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import EarnFeature from "@/views/Main/main-design-v2/views/home/components/earn-section/EarnFeature";

export default {
  name: "EarnOptionTemplate",
  components: {EarnFeature},
  props: {
    bgColor: {type: String, default: "var(--theme-accent-dark)"},
    contentBgColor: {type: String, default: "var(--theme-bg-lb)"},
  }
}
</script>

<style lang="scss" scoped>
.earn-option-header, .earn-option-content {
  padding: 48px 56px;
}

.earn-option-header {
  border-radius: 48px 48px 0 0;
}

.earn-option-content {
  border-radius: 0 0 48px 48px;
}

@media screen and (max-width: 1200px) {
  .earn-option-header, .earn-option-content {
    padding: 32px;
  }
}

@media screen and (max-width: 768px) {
  .earn-option-header, .earn-option-content {
    padding: 16px;
  }
}
</style>
