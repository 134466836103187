<template>
  <validation-observer ref="calculator" class="md-calculator-form" v-if="$blockchain.tickers.values[User.currency || 'USD']">

    <b-form-group class="form-group-with-label investing">
      <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
        <div style="flex-grow: 1;">

          <validation-provider
              #default="{ errors }"
              :rules="rules"
              name="Investments"
          >
            <!-- rules="required" -->
            <div v-if='errors[0]' class='text-danger'>
                            <span class="form-label" style="font-size: 12px; font-weight: 500;">
                                {{$t('calculator.invest')}}
                            </span>
              <feather-icon icon='AlertCircleIcon'/>
            </div>
            <span v-else class='text-muted form-label' style="font-size: 12px;">{{$t('calculator.invest')}}</span>

            <b-form-input
                v-model.number="total_price"
                :placeholder="$t('calculator.investment-amount')"
                type="number"
                @change="calcTotalThs()"
                class="value-input-calc"
            />
            <!-- :formatter="summFormatter" -->
          </validation-provider>

        </div>
        <div class="mt-auto metric">
          <b-dropdown
              :text="ticker"
              right
              size="sm"
              variant="muted"
          >
            <b-dropdown-item @click="changeCurrencyType('USD')">USD</b-dropdown-item>
            <b-dropdown-item @click="changeCurrencyType('BTC')">BTC</b-dropdown-item>

          </b-dropdown>
        </div>
      </div>

      <vue-slider
          v-model="total_price"
          :contained="false"
          :dotSize="18"
          :duration="0.25"
          :height="1"
          :interval="sliderOptions.step"
          :max="sliderOptions.max"
          :min="sliderOptions.min"
          tooltip="none"
          @change="calcTotalThs()"
      >
        <template v-slot:dot>
          <div class="slider-dot">
            <img alt="" src="@/assets/images/icons/slider-arrow.svg">
          </div>
        </template>
      </vue-slider>

    </b-form-group>

    <div v-if="ticker === 'USD'" class="d-flex justify-content-between mb-1">
      <span class="text-muted" style="font-size: 12px;">100$</span>
      <span class="text-muted" style="font-size: 12px;">10 {{$t('calculator.thousand')}} $</span>
    </div>
    <div v-else class="d-flex justify-content-between mb-1">
      <span class="text-muted" style="font-size: 12px;">{{ sliderOptions.min }} ₿</span>
      <span class="text-muted" style="font-size: 12px;">{{ sliderOptions.max }} ₿</span>
    </div>

    <b-form-group class="form-group-with-label period">

      <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
        <div style="flex-grow: 1;">
          <validation-provider
              #default="{ errors }"
              name="Investments"
              rules="required|integer"
          >
            <div v-if='errors[0]' class='text-danger'>
                            <span class="form-label" style="font-size: 12px; font-weight: 500;">
                                {{$t('calculator.contract-validity')}}
                            </span>
              <feather-icon icon='AlertCircleIcon'/>
            </div>
            <span v-else class='text-muted form-label' style="font-size: 12px;">{{$t('calculator.contract-validity')}}</span>
            <b-form-input
                v-model.number="period"
                :formatter="periodFormatter"
                :placeholder="$t('calculator.contract-validity')"
                type="number"
                @input="calcTotalThs()"
                class="value-input-calc"

            />
          </validation-provider>
        </div>
        <div class="mt-auto metric">
          <span class="text-muted">{{$t('calculator.M')}}</span>
        </div>
      </div>

      <vue-slider
          v-model="period"
          :contained="false"
          :dotSize="18"
          :duration="0.25"
          :height="1"
          :interval="1"
          :max="36"
          :min="24"
          tooltip="none"
          @change="calcTotalThs()"
      >
        <template v-slot:dot>
          <div class="slider-dot">
            <img alt="" src="@/assets/images/icons/slider-arrow.svg">
          </div>
        </template>
      </vue-slider>
    </b-form-group>

    <div class="d-flex justify-content-between mb-1">
      <span class="text-muted" style="font-size: 12px;">24 {{$t('decl.months')}}</span>
      <span class="text-muted" style="font-size: 12px;">36 {{$t('decl.months')}}</span>
    </div>

    <b-card bg-variant="gray" class="card-vs" no-body style="margin-top: 32px;">

      <b-card-title style="font-size: 14px; margin: 16px 20px 8px 20px;">
        {{$t('calculator.mining-advantages')}}
        <span class="text-muted">
                    <feather-icon
                        v-b-popover.hover.top="$t('popovers.comparison')"
                        class="cursor-pointer info-hover"
                        icon="AlertCircleIcon"
                        style="margin-left: 6px;"
                    />
                </span>
      </b-card-title>

      <b-card-body style="padding: 0 20px 20px 20px;">

        <span>{{$t('calculator.buy-contract')}}</span>

        <div class="d-flex">
          <b-progress-bar
              :max="100"
              :min="0"
              :value="( period / 24 ) * 100 "
              class="mt-auto mb-auto mr-1"
              label=" "
              style="height: 8px; border-radius: 8px; background-color: #47BCAA; max-width: 75%;"
          />
          <div class="vs_earn" style="margin-right: 4px;">{{ summary_in_btc.formatMoney(5, ' ', ' ') }} ₿</div>

        </div>
        <p class="text-primary font-medium-5" style="margin-bottom: 4px;">vs</p>
        <span>{{$t('calculator.buy-btc')}}</span>
        <div class="d-flex ">
          <b-progress-bar
              :max="100"
              :min="0"
              :value="( (period / 3) / 24 ) * 100 "
              class="mt-auto mb-auto mr-1"
              label=" "
              style="height: 8px; border-radius: 8px; background-color: #9CA2B1; max-width: 75%;"
          />
          <div class="vs_earn" style="margin-right: 4px;">{{ total_btc_price.formatMoney(5, ' ', ' ') }} ₿</div>
        </div>
      </b-card-body>
    </b-card>
  </validation-observer>
</template>

<script>

import User from "@/modules/user/"

import VueSlider from 'vue-slider-component'
import Blockchain from "@/modules/blockchain"

import {ValidationObserver, ValidationProvider} from 'vee-validate'

import {digits, integer, max, maxint, min, minint, required} from '@/@core/utils/validations/validations'

export default {
  props: {
    total_ths: {
      type: Number,
      default: 0
    },
    current_price: {
      type: Number,
      default: 0
    },
    total_btc_price: {
      type: Number,
      default: 0
    },

    calcType: {
      type: Number,
      default: 0 // history
    },

    diff_history: {
      type: Number,
      default: 10,
    },

    btc_percent: {
      type: Number,
      default: 10
    },

    diff_percent: {
      type: Number,
      default: 0
    },


    market_price_percent: {
      type: Number,
      default: 10
    }
  },

  data() {
    return {
      Blockchain,

      sliderOptions: {
        step: 100,
        max: 10000,
        min: 100
      },

      User,

      ticker: 'USD',

      price: parseFloat(User.contracts.values.P),
      price_with_discount: User.contracts.values.P,

      ths: 100,
      period: 24,
      investments: 320,
      total_price: 1000,
      max_ths: 10000,
      is_loading: false,

      btn_month_percent: 9.5,
      type: 2,
      paymentType: 'card',

      required,
      minint,
      maxint,
      min,
      max,
      digits,
      integer,

      rules: "required|minint:100|maxint:10000"

    }
  },
  methods: {

    summFormatter(args) {

      let val = parseInt(args);

      if (val < 100) {
        val = 100;
      } else if (val > 10000) {
        val = 10000;
      }

      return val;

    },

    periodFormatter(args) {

      let val = parseInt(args);

      if (val < 24) {
        val = 24;
      } else if (val > 36) {
        val = 36;
      }

      return val;

    },

    changeCurrencyType(value) {

      if (this.ticker === value) return;

      this.ticker = value;
      switch (value) {

        case 'USD' :

          this.total_price = this.total_price * this.$blockchain.tickers.values.USD.last;

          this.total_price = this.total_price.toFixed(0);

          this.sliderOptions.step = 100;
          this.sliderOptions.min = 100;
          this.sliderOptions.max = 10000;

          this.rules = "required|minint:" + this.sliderOptions.min + "|maxint:" + this.sliderOptions.max;

          break;


        case 'BTC' :

          this.total_price = this.total_price / this.$blockchain.tickers.values.USD.last;

          this.sliderOptions.step = 0.00001;
          this.sliderOptions.min = parseFloat((100 / this.$blockchain.tickers.values.USD.last).toFixed(5));
          this.sliderOptions.max = parseFloat((10000 / this.$blockchain.tickers.values.USD.last).toFixed(5));

          this.rules = "required|minint:" + this.sliderOptions.min + "|maxint:" + this.sliderOptions.max;

          break;

      }

    },


    getPeriodProfit() {
      let profit = 0;
      let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
      let diff = parseInt(this.$user.contracts.values.D);

      for (let i = 0; i < this.period; i++) {
          profit += (86400 * 30 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( diff * Math.pow(2, 32) );

          diff += temp;
      }

      return profit;
    },

    getDynamicPeriodProfit() {
      return (this.btn_month_percent * this.getMounthProfit() * this.period) + this.getPeriodProfit();
    },

    getMounthProfit() {
      return (((86400 * 30 * this.$user.contracts.values.R * (this.$user.contracts.values.H * this.ths)) / (this.$user.contracts.values.D * Math.pow(2, 32))) * this.$blockchain.tickers.values[User.currency].last) - (this.ths * 2.5);
    },

    getDailyProfit() {
      return (((86400 * this.$user.contracts.values.R * (this.$user.contracts.values.H * this.ths)) / (this.$user.contracts.values.D * Math.pow(2, 32))) * this.$blockchain.tickers.values[User.currency].last);
    },

    getTotalBTCPrice() {

      if (this.ticker === 'USD') {
        return this.total_price / this.$blockchain.tickers.values.USD.last;
      } else {
        return this.total_price;
      }

    },

    calcTotalThs() {

      if (this.ticker === 'USD') {
        if (this.total_price < 100) {
          this.total_price = 100;
        } else if (this.total_price > 10000) {
          this.total_price = 10000;
        }
      } else {
        if (this.total_price < this.sliderOptions.min) {
          this.total_price = this.sliderOptions.min;
        } else if (this.total_price > this.sliderOptions.max) {
          this.total_price = this.sliderOptions.max;
        }
      }

      var total_price = 0;

      if (this.ticker === 'USD') {
        total_price = this.total_price;
      } else {
        total_price = this.total_price * this.$blockchain.tickers.values.USD.last;
      }

      let power = total_price / this.getCurrentPrice();

      if (power > 10000) {
        power = 10000;
      }

      this.ths = power;

      this.$refs.calculator.validate().then(success => {
        if (!success || this.ths === 0) return;
        this.$emit("dataChanged", {
          "total_btc_price": this.getTotalBTCPrice(),
          "period": this.period,
          "period_profit": this.getPeriodProfit(),
          "total_ths": this.ths,
          "current_price": this.getCurrentPrice()
        });
      });

    },

    getSizeDiscount() {

      let discountForSize = 0;

      if (this.ths > 1) {
        discountForSize = 2500 * (this.power / 10000);
      }

      return (discountForSize / 10000) * 100;

    },

    getPeriodDiscount() {

      let discount = 0;

      if (this.form.period > 24) {
        discount = (this.period - 24) * 0.5;
      }

      return (discount / this.price) * 100;

    },


    getCurrentPrice() {

    let price = this.price * 12;
    let price_per_month = this.price;

    for(let i = 13 ; i <= this.period; i++ ) {
        price_per_month = price_per_month * 0.9;
        price = price + price_per_month;
    }

    this.price_with_discount = price;

    return price;

    },

    getTotalPrice() {
      this.total_price = this.ths * this.getCurrentPrice();
    },

    getPeriodHolder() {
      var placeholder = this.declOfNum(this.period, ['месяц', 'месяца', 'месяцев']);
      return `${placeholder}`;
    },

    declOfNum(number, titles) {
      let cases = [2, 0, 1, 1, 1, 2];
      if (number === 0) {
        return titles[2];
      }
      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },
  },
  computed: {

    fee() {
        return (this.period_profit * 0.025) * this.market_price;
    },

    fee_btc() {
        return this.period_profit * 0.025;
    },
    
    service() {
        return (this.period_profit * 0.05) * this.market_price;
    },

    service_btc() {
        return this.period_profit * 0.05;
    },

    difficult() {

      let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
      let growing = temp * this.period;
      let diff = parseInt(this.$user.contracts.values.D) + growing;

      if (diff <= 1000000000) {
        diff = 1000000000;
      }

      return parseInt(diff);

    },

    market_price() {
       // формула вычета сложно процента 
        if (User.currency === 'USD') {
            return this.$blockchain.tickers.values[User.currency].last * (Math.pow((1 + this.market_price_percent / 100), this.period))
        } else if  (User.currency === 'BTC') {
            return 1;
        } else {
            return this.$blockchain.tickers.values[User.currency].last;
        }
    },

    market_price_btc() {

      let temp = this.$blockchain.tickers.values['BTC'].last * (this.market_price_percent / 100);
      let growing = temp * this.period;

      return this.$blockchain.tickers.values['BTC'].last + growing;

    },

    period_profit() {
      // return (((84600 * 30 * this.period * this.$user.contracts.values.R * (this.$user.contracts.values.H * this.total_ths)) / (this.difficult * Math.pow(2, 32))));

        let profit = 0;
        let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
        let diff = parseInt(this.$user.contracts.values.D);

        for (let i = 0; i < this.period; i++) {
            profit += (86400 * 30 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( diff * Math.pow(2, 32) );

            diff += temp;
        }

        return profit;
    },

    energy_payout_btc() {
      // за весь период
      //return ( this.total_ths * ( this.$user.contracts.values.E * 24 * 30 ) * this.period ) / this.$blockchain.tickers.values.USD.last;

      // за месяц
      return (this.total_ths * (this.$user.contracts.values.E * 24 * 30)) / this.$blockchain.tickers.values.USD.last;

    },

    energy_payout() {

      if (User.currency === "BTC") {
        return this.energy_payout_btc;
      }

      return this.energy_payout_btc * this.$blockchain.tickers.values[User.currency].last;

    },

    price_in_btc() {
      return this.current_price / this.period / this.$blockchain.tickers.values.USD.last;
    },

    summary() {
      return (this.period_profit * this.market_price) - this.energy_payout * this.period;
    },

    summary_in_btc() {
      return this.period_profit - this.energy_payout_btc - this.fee_btc - this.service_btc;
    },

    percent() {
      // return (( this.summary  / this.investments) * 100).toFixed(2);
      return (((this.summary - this.fee - this.investments) / this.investments) * 100).toFixed(2);
    },

    month_energy_payout_usd() {
        // за месяц
        return this.total_ths * ( this.$user.contracts.values.E * 24 * 30 );
    },

    energy_payout() {
        let energy_btc = 0;

        let month_energy_usd = this.month_energy_payout_usd
        let usd_rate = this.$blockchain.tickers.values.USD.last; 

        for (let i = 0; i < this.period; i++) {
            energy_btc += month_energy_usd / usd_rate;

            usd_rate = usd_rate * (1 + this.market_price_percent / 100);
        }

        if (User.currency === "BTC") {
            return energy_btc;
        } else if (User.currency === "USD") {
            return month_energy_usd * this.period;
        } else {
            return energy_btc * this.$blockchain.tickers.values[User.currency].last;
        }
    },

    energy_payout_btc() {
        let energy_btc = 0;

        let month_energy_usd = this.month_energy_payout_usd
        let usd_rate = this.$blockchain.tickers.values.USD.last; 

        for (let i = 0; i < this.period; i++) {
            energy_btc += month_energy_usd / usd_rate;

            usd_rate = usd_rate * (1 + this.market_price_percent / 100);
        }

        return energy_btc;
    }

  },

  components: {
    VueSlider,
    ValidationProvider,
    ValidationObserver,
  },
  watch: {
    "User.currency": function () {
      this.$emit("dataChanged", {
        "total_btc_price": this.getTotalBTCPrice(),
        "period": this.period,
        "period_profit": this.getPeriodProfit(),
        "total_ths": this.ths,
        "current_price": this.getCurrentPrice()
      });
    },

    "$blockchain.tickers.values.USD.last": function () {
      this.$emit("dataChanged", {
        "total_btc_price": this.getTotalBTCPrice(),
        "period": this.period,
        "period_profit": this.getPeriodProfit(),
        "total_ths": this.ths,
        "current_price": this.getCurrentPrice()
      });
    }
  },
  mounted() {
    this.calcTotalThs();
  }

}

</script>

<style lang="scss">
@import '~@/@core/scss/vue/libs/vue-slider.scss';

.md-calculator-form {
  .vue-slider-dot {
    height: auto !important;
    width: auto !important;
  }

  .value-input-calc {
    padding-bottom: 6px;
    padding-top: 6px;
    height: 38px;
  }

  .slider-dot {
    width: 24px;
    height: 24px;
    background-color: var(--text-primary-color);
    border-radius: 100px;
    padding: 4px;
    display: flex;

    img {
      margin: auto;
      width: 100%;
    }
  }

  .vue-slider {
    .vue-slider-process, .vue-slider-rail {
      transition: background-color .3s ease;
    }
  }

  .vue-slider:hover {
    .vue-slider-process {
      background-color: rgba(0, 0, 0, .9) !important;
    }

    .vue-slider-rail {
      background-color: rgba(0, 0, 0, .1) !important;
    }
  }

  .vue-slider-rail {
    background-color: rgba(0, 0, 0, .2);
  }

  .vue-slider-dot {
    cursor: pointer;
  }

  .vue-slider-process {
    background-color: var(--text-primary-color);
  }

  .investing, .period {
    padding: 0 0 10px 0;

    input {
      background: none !important;
      font-weight: bold !important;
      font-size: 24px !important;
    }

    .form-label {
      font-weight: normal !important;
      font-size: 13px !important;
      color: var(--text-primary-color) !important;
      padding-bottom: 6px !important;
    }

    .metric {
      button, span {
        font-weight: normal !important;
        font-size: 24px !important;
        color: var(--text-primary-color) !important;
      }
    }
  }

  .vs_earn {
    font-weight: 600;
    font-size: 13px;
  }

  .card-vs {
    box-shadow: -14.3363px 43.1971px 52.8651px rgba(81, 78, 120, 0.04);
  }
}
</style>
