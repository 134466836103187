<template>
  <main>
    <b-modal
        id="consultation"
        centered
        hide-footer
        modal-class="close-contains"
        size="lg"
    >
      <ModalForm/>
    </b-modal>

    <b-modal
        id="video"
        body-class="p-2"
        centered
        modal-class="close-contains"
        hide-footer
        size="lg"
    >
      <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen class="video-viewer"
              frameborder="0"
              height="100%" :src="$i18n.locale === 'en' ? 'https://www.youtube.com/embed/d8faTAIGUkM?autoplay=1' : 'https://www.youtube.com/embed/ePZLCVylmyY?autoplay=1'"
               title="Qubity.io"
              width="100%"></iframe>
    </b-modal>

    <b-modal
        id="special_offer"
        modal-class="close-contains"
        body-class="p-2"
        centered
        hide-footer
        size="xl"
    >
      <start-earning class="mt-5">
        <q-header :centered="false" level="1" size="xl">
          <b>Начните зарабатывать прямо сейчас —</b> подключите выгодный контракт <b>в 1 <span
            class="position-relative">клик<img alt="" class="lines position-absolute"
                                               src="@/assets/images/site/icons/grebeshki_blue_bottom.svg"></span></b>
        </q-header>
      </start-earning>
      <div class="d-flex justify-content-center">
        <q-btn @click="$bvModal.hide('special_offer')">Спасибо, не надо</q-btn>
      </div>
    </b-modal>
    <video-popup/>
    <short-description/>
    <media-ratings/>
    <earn-mining class="mt-5" id="calculator" />
    <guarantee-earnings/>
    <data-center/>
    <Command/>
    <self-mining class="mt-5"/>
    <ceo-quote/>
    <client-reviews/>
    <text-marquee/>
    <!-- <meaning-leaders/> -->
    <three-step/>
    <start-earning v-if="cloudOffers.length > 0" class="mt-5 container-px-lg" id="offers">
      <q-header :centered="false" level="1" size="xl">
        <b>{{$t('lending.start-earning.header-a')}}</b>{{$t('lending.start-earning.header-b')}}<b>{{$t('lending.start-earning.header-c')}}<span
          class="position-relative">{{$t('lending.start-earning.header-d')}}<img alt="" class="lines position-absolute"
                                             src="@/assets/images/site/icons/grebeshki_blue_bottom.svg"></span></b>
      </q-header>
    </start-earning>
    <find-out-more class="mt-5"/>
    <faq class="mt-5"/>
    <i-have-no-question class="mt-5"/>
  </main>
</template>

<script>
import {mapActions} from "vuex";

import ShortDescription from "@/views/Main/main-design-v2/views/home/short-description";
import ModalForm from "@/views/Main/main-design-v2/components/ModalForm";
import MediaRatings from "@/views/Main/main-design-v2/views/home/media-ratings";
import EarnMining from "@/views/Main/main-design-v2/views/home/earn-mining";
import GuaranteeEarnings from "@/views/Main/main-design-v2/views/home/guarantee_earnings";
import DataCenter from "@/views/Main/main-design-v2/views/home/data-center";
import SelfMining from "@/views/Main/main-design-v2/views/home/self-mining";
import StartEarning from "@/views/Main/main-design-v2/views/home/start-earning";
import ClientReviews from "@/views/Main/main-design-v2/views/home/client-reviews";
import TextMarquee from "@/views/Main/main-design-v2/views/home/text-marquee";
// import MeaningLeaders from "@/views/Main/main-design-v2/views/home/meaning-leaders";
import FindOutMore from "@/views/Main/main-design-v2/views/home/find-out-more";
import faq from "@/views/Main/main-design-v2/views/home/faq";
import IHaveNoQuestion from "@/views/Main/main-design-v2/views/home/i-have-no-question";
import ThreeStep from "@/views/Main/main-design-v2/views/home/three-step";
import VideoPopup from "@/views/Main/main-design-v2/views/home/components/VideoPopup";
import CeoQuote from "@/views/Main/main-design-v2/views/home/ceo-quote";
import Command from "@/views/Main/main-design-v2/views/home/command";

import offers from "@/modules/contents/offers"

export default {
  components: {
    CeoQuote,
    VideoPopup,
    DataCenter,
    // MeaningLeaders,
    TextMarquee,
    ClientReviews,
    EarnMining,
    MediaRatings,
    ShortDescription,
    GuaranteeEarnings,
    SelfMining,
    StartEarning,
    IHaveNoQuestion,
    FindOutMore,
    faq,
    ThreeStep,
    Command,
    ModalForm
  },
  data() {
    return {
      offers,
      modalCall: {
        name: "",
        phone: "",
        option: "call"
      },
      modalErrors: {
        name: false,
        phone: false
      },
      modalSubmitted: false
    }
  },
  methods: {
    ...mapActions(["setEarnOption"]),

    onModalSubmit() {
      this.modalSubmitted = true
    },

    scroll (n = null) {
      setTimeout(() => {
        document.getElementById( 'calculator' ).scrollIntoView({
          behavior: "smooth"
        });

        if (n) this.setEarnOption(n)
      }, 1500 );
     }
  },
  computed: {
     cloudOffers() {
      if (!offers.items.length) return [];

      let clouds = offers.items.filter(l => (l.type === 1));

      if (clouds.length > 3) return clouds.slice(0, 3)
      else return clouds
    }
  },
  mounted() {
    if (!offers.items.length) offers.get();

    if (this.$route.hash == '#calculator') this.scroll()
    else if (this.$route.hash == '#miners') this.scroll(2)

    if (this.$route.hash == '#calculator') this.scroll()
    else if (this.$route.hash == '#miners') this.scroll(2)

    if (this.$route.hash) {
      setTimeout(() => {
        this.$smoothScroll({
          scrollTo: document.getElementById(this.$route.hash.slice(1)),
          duration: 800,
          updateHistory: false
        })
        //  document.getElementById(this.$route.hash.slice(1)).scrollIntoView({
        //   behavior: "smooth"
        // });
      }, 0)
    }

    let obs = new IntersectionObserver((entries) => {
      entries.forEach(el => {
        if (el.isIntersecting) {
          el.target.classList.add(el.target.dataset.animated || "animate__fadeInDown", "animate__fast")
          el.target.classList.remove("no-show")
        }
      })
    }, {root: null, threshold: 1})

    document.querySelectorAll("*[data-animated]").forEach(el => {
      el.classList.add("animate__animated", "no-show")
      obs.observe(el)
    })

  }
}
</script>

<style lang="scss" scoped>
@supports (aspect-ratio: 16 / 9) {
  .video-viewer {
    aspect-ratio: 16 / 9;
  }
}

@supports not (aspect-ratio: 16 / 9) {
  .video-viewer {
    height: 430px;
  }
}
</style>

<style lang="scss">
.close-contains {
  .close {
    transform: translate(-33px, 2px) !important;
    background-color: rgba(169, 169, 169, .25) !important;
    box-shadow: none !important;
    color: #A9A9A9;
  }
}

@media screen and (min-width: 567px) and (max-width: 1200px) {
  #special_offer {
    .modal-dialog {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
