<template>
  <section class="step_main_img py-5">
    <div class="container-px-lg container d-flex flex-column">
      <q-header class="pt-5 three-step-header d-none d-md-inline mx-auto" color="#fff" level="1" size="xl">
        {{$t('lending.three-step.header-a')}}<br>{{$t('lending.three-step.header-b')}}<span class="position-relative zindex-1"><img
          alt="" class="position-absolute zindex-minus-1 ellipse-3-steps" src="@/assets/images/site/ellipse.svg"
          style="transform: rotate(1.85deg); width: 110%; left: -4.5%; top: -2%">{{$t('lending.three-step.header-c')}}</span>
      </q-header>
      <q-header :centered="false" class="pt-3 pl-2 three-step-header d-md-none d-inline" color="#fff" level="1"
                size="xl">
         {{$t('lending.three-step.header-a')}}<br> {{$t('lending.three-step.header-b')}}<span class="position-relative zindex-1"><img
          alt="" class="position-absolute zindex-minus-1" src="@/assets/images/site/ellipse.svg"
          style="transform: rotate(1.85deg); width: 105%; left: -2.5%"> {{$t('lending.three-step.header-c')}}</span>
      </q-header>
      <p class="step_second_text text-left text-md-center ml-2">{{$t('lending.three-step.subheader-a')}}<br>
        {{$t('lending.three-step.subheader-b')}}
      </p>
      <b-row style="padding-bottom: 50px">
        <b-col data-animated class="step_col" cols="12" lg="4">
          <div class="d-flex flex-column flex-md-row flex-lg-column pl-2 pl-md-0">
            <div class="step-num">
              <span>01</span>
            </div>
            <div class="my-auto">
              <h5 style="color: white">{{$t('lending.three-step.step-1-title')}}</h5>
              <p class="step_last_text mb-1">
                {{$t('lending.three-step.step-1-text')}}
              </p>
              <q-btn small-padding to="/register" variant="secondary">{{$t('lending.three-step.step-1-btn')}}</q-btn>
            </div>
          </div>
        </b-col>
        <b-col data-animated class="step_col" cols="12" lg="4">
          <div class="d-flex flex-column flex-md-row flex-lg-column pl-2 pl-md-0">
            <div class="step-num">
              <span>02</span>
            </div>
            <div class="my-auto">
              <h5 style="color: white">{{$t('lending.three-step.step-2-title')}}</h5>
              <p class="step_last_text">{{$t('lending.three-step.step-2-text')}}
              </p>
            </div>
          </div>
        </b-col>
        <b-col data-animated class="step_col" cols="12" lg="4">
          <div class="d-flex flex-column flex-md-row flex-lg-column pl-2 pl-md-0">
            <div class="step-num">
              <span>03</span>
            </div>
            <div class="my-auto">
              <h5 style="color: white">{{$t('lending.three-step.step-3-title')}}</h5>
              <p class="step_last_text">{{$t('lending.three-step.step-3-text')}}</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>

export default {
  name: "three-step",
}
</script>

<style lang="scss" scoped>
.step_main_img {
  background: url("../../../../../assets/images/site/background-three-step.png") no-repeat;
  background-size: cover;
}

.step_main_text {
  color: white;
  text-align: center;
  padding-top: 7vw;
  position: relative;
  z-index: 1;
}

.text-ellipse {
  position: absolute;
  right: 40%;
  width: 17.5%;
  z-index: -1;
}

.step_second_text {
  margin-top: 2vw;
  color: white;
  text-align: center;
  font-size: 16px;
}

.step-num {
  font-size: 96px;
  width: 120px;
  margin-right: 50px;
  font-weight: 500;
  color: var(--theme-accent-dark);

  span {
    text-shadow: 0 0 2px white;
  }
}

.step_last_text {
  color: white;
  font-size: 16px;
  padding: 0;
  margin-bottom: 0;
}

.step_link {
  border-bottom: 1px solid white;
  padding-bottom: 2px;
  color: white;
}
</style>
