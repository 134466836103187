<template>
  <div class="q-carousel__wrapper">
    <div class="q-carousel" ref="carousel">
      <div class="q-carousel__inner" ref="inner">
        <slot>
        </slot>
      </div>
      <div class="q-carousel-control mt-2 d-flex justify-content-between">
        <div class="dots position-relative mx-auto ml-md-0">
          <div class="active-dots position-absolute"
               :style="'left:' + (13 * currentSlide) + 'px;' + ((currentSlide === childrenCount - reduceDots - 1) ? 'width: 10px' : '')"></div>
          <div class="dot" v-for="i in Array(childrenCount - reduceDots).keys()" @click="setSlide(i)"></div>
        </div>
        <div class="control d-none d-md-flex" :class="controlClass">
          <button class="mr-1" @click="prevSlide">
            <img src="@/assets/images/icons/arrow_left.svg" alt="">
          </button>
          <button @click="nextSlide">
            <img src="@/assets/images/icons/arrow_right.svg" alt="">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QCarousel",
  props: {
    controlClass: String
  },
  data() {
    return {
      currentSlide: 0,
      startTouchX: null,
      currentTouchX: null,
      childrenCount: 0,
      reduceDots: 0
    }
  },
  computed: {
    childrenWidth: function () {
      if (this.$refs.inner) {
        return this.$refs.inner.children[0].clientWidth
      }
    }
  },
  methods: {
    nextSlide() {
      if (this.currentSlide < this.$refs.inner.children.length - 1 - this.reduceDots) {
        this.currentSlide++
        this.$refs.inner.scrollTo({left: this.currentSlide * this.childrenWidth, behavior: "smooth"})
      }
    },
    prevSlide() {
      if (this.currentSlide !== 0) {
        this.currentSlide--
        this.$refs.inner.scrollTo({left: this.currentSlide * this.childrenWidth, behavior: "smooth"})
      }
    },
    setSlide(n) {
      this.currentSlide = n
      this.$refs.inner.scrollLeft = this.currentSlide * this.childrenWidth
    }
  },
  mounted() {
    this.childrenCount = this.$refs.inner.children.length
    const width = document.body.clientWidth

    if (width > 768) {
      this.$refs.inner.addEventListener("scroll", () => {
        this.currentSlide = Math.trunc(this.$refs.inner.scrollLeft / this.childrenWidth)
      })
    } else {
      this.$refs.inner.style.overflowX = "hidden"

      this.$refs.carousel.addEventListener("touchmove", (e) => {
        let evt = e.touches[0]
        if (!this.startTouchX) {
          this.startTouchX = evt.clientX
        } else {
          this.currentTouchX = evt.clientX
        }
      })

      this.$refs.carousel.addEventListener("touchend", (e) => {
        this.deltaTouchX = this.currentTouchX - this.startTouchX
        this.startTouchX = null
        this.currentTouchX = null

        if (this.deltaTouchX < -50) {
          this.nextSlide()
        } else if (this.deltaTouchX > 50) {
          this.prevSlide()
        }
      })
    }


    if (width < 768) {
      this.reduceDots = 0
    } else if (width < 992) {
      this.reduceDots = 1
    } else {
      this.reduceDots = 2
    }
  }
}
</script>

<style scoped lang="scss">
@import "styles/q-carousel-slider";
</style>
