<template>
  <section class="bg-lb py-4">
    <div class="container container-px-lg">
      <b-row class="mb-5">
        <b-col class="d-flex flex-column" cols="12" lg="6">
          <b-row class="mx-0">
            <b-col class="pl-0" cols="8" lg="12">
              <q-header :centered="false" level="1" size="xl">
                <b>{{$t('lending.self-mining.header-a')}}<span class="p-relative">{{$t('lending.self-mining.header-b')}}<img
                    alt="" class="lines position-absolute d-none d-md-inline-block"
                    src="@/assets/images/site/icons/grebeshki_blue_bottom.svg"></span><br>{{$t('lending.self-mining.header-c')}}</b>
              </q-header>
            </b-col>
            <b-col class="d-block d-lg-none" cols="4">
              <img alt="" class="mt-2 w-100" src="@/assets/images/site/watching_man.png">
            </b-col>
            <b-col class="p-1 mt-2 compare-card" cols="12">
              <b-col class="d-flex mb-1" cols="12">
                <b-col class="my-auto" cols="5">
                  <q-header :centered="false" color="var(--text-primary-color)" level="5" size="">
                    <b>{{$t('lending.self-mining.home-mining')}}</b>
                  </q-header>
                </b-col>
                <b-col class=" my-auto px-0" cols="2">
                  <img alt="" width="45" src="@/assets/images/site/vs.png">
                </b-col>
                <b-col class="my-auto pl-0" cols="5">
                  <q-header :centered="false" color="var(--text-primary-color)" level="5" size="">
                    <b>{{$t('lending.self-mining.qubity-mining')}}</b>
                  </q-header>
                </b-col>
              </b-col>
              <b-col class="d-flex compare-row" cols="12">
                <b-col class="d-md-flex d-block" cols="7">
                  <img alt="" class="cross compare-icon mt-0 mb-0 mb-md-5" src="@/assets/images/site/icons/cross_in_circle.svg">
                    <p v-html="$t('lending.self-mining.hm-collapse-1')"></p>
                </b-col>
                <b-col class="d-md-flex d-block qubityio-row pl-0" cols="5">
                  <img alt="" class="cross compare-icon mt-0 mb-0 mb-md-5" src="@/assets/images/site/icons/done.svg">
                  <p><b v-html="$t('lending.self-mining.qm-collapse-1')"></b></p>
                </b-col>
              </b-col>
              <b-col class="px-2 mb-1" cols="12">
                <hr style="margin: 0"/>
              </b-col>
              <b-col class="d-flex compare-row" cols="12">
                <b-col class="d-md-flex d-block" cols="7">
                  <img alt="" class="cross compare-icon mt-0 mb-0 mb-md-5" src="@/assets/images/site/icons/cross_in_circle.svg">
                    <p v-html="$t('lending.self-mining.hm-collapse-2')"></p>
                </b-col>
                <b-col class="d-md-flex d-block qubityio-row pl-0" cols="5">
                  <img alt="" class="cross compare-icon mt-0 mb-0 mb-md-5" src="@/assets/images/site/icons/done.svg">
                  <p><b>{{$t('lending.self-mining.qm-collapse-2')}} {{this.$user.contracts.values.K}}$&nbsp;&nbsp;{{$t('lending.self-mining.kwh')}}</b></p>
                </b-col>
              </b-col>
              <b-col class="px-2 mb-1" cols="12">
                <hr style="margin: 0"/>
              </b-col>
              <b-col class="d-flex compare-row" cols="12">
                <b-col class="d-md-flex d-block" cols="7">
                  <img alt="" class="cross compare-icon mt-0 mb-0 mb-md-5" src="@/assets/images/site/icons/cross_in_circle.svg">
                    <p v-html="$t('lending.self-mining.hm-collapse-3')"></p>
                </b-col>
                <b-col class="d-md-flex d-block qubityio-row pl-0" cols="5">
                  <img alt="" class="cross compare-icon mt-0 mb-0 mb-md-5" src="@/assets/images/site/icons/done.svg">
                    <p><b v-html="$t('lending.self-mining.qm-collapse-3')"></b></p>
                </b-col>
              </b-col>
              <b-col class="px-2 mb-1" cols="12">
                <hr style="margin: 0"/>
              </b-col>
              <b-col class="d-flex compare-row" cols="12">
                <b-col class="d-md-flex d-block" cols="7">
                  <img alt="" class="cross compare-icon mt-0 mb-0 mb-md-5" src="@/assets/images/site/icons/cross_in_circle.svg">
                    <p v-html="$t('lending.self-mining.hm-collapse-4')"></p>
                </b-col>
                <b-col class="d-md-flex d-block qubityio-row pl-0" cols="5">
                  <img alt="" class="cross compare-icon mt-0 mb-0 mb-md-5" src="@/assets/images/site/icons/done.svg">
                    <p><b v-html="$t('lending.self-mining.qm-collapse-4')"></b></p>
                </b-col>
              </b-col>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="d-lg-block d-none my-auto" cols="6">
          <img alt="" class="w-100" src="@/assets/images/site/watching_man.png">
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "SelfMining"
}
</script>

<style lang="scss" scoped>
.watching-man-mobile {
  width: 100%;
}

.lines {
  top: -10px;
}

.compare-card {
  border-radius: 32px;
  background: #fff;
  box-shadow: 0 64px 64px rgba(31, 47, 70, 0.12);
  font-size: 16px;

  .cross {
    width: 24px;
  }

  .home-row p {
    color: var(--text-secondary-color);
  }
}

@media screen and (max-width: 429px) {
  .compare-card {
    font-size: 14px;
  }
}

.compare-icon {
  margin-right: 8px;
}
</style>
