<template>
  <div class="earn-options-selector position-relative zindex-1">
    <div class="d-flex">
      <button class="option" @click="toggleSelected(0)">
        {{$t('lending.tabs.rent-hashrate')}}
      </button>
      <button class="option" @click="toggleSelected(1)">
        {{$t('lending.tabs.buy-miner')}}
      </button>
    </div>
    <div :class="getEarnOption ? 'selected-right' : 'selected-left'" class="selected-slider position-absolute"></div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "EarnOptionsSelector",
  model: {
    prop: "selectedOption",
    event: "change"
  },
  props: {
    selectedOption: {default: 0}
  },
  computed: {
    ...mapGetters(["getEarnOption"]),
  },
  methods: {
    ...mapActions(["setEarnOption"]),
    toggleSelected(n) {
      this.setEarnOption(n)
    }
  }
}
</script>

<style lang="scss" scoped>
.earn-options-selector {
  border-radius: 100px;
  width: 100%;
  border: 1px solid rgba(26, 86, 255, 0.18);
  padding: 8px;
}

.option {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: var(--theme-accent-light);
  font-weight: 600;
  padding: 18px;
  border: none;
  background: none;
  outline: none;
}

@media screen and (max-width: 1200px) {
  .option {
    font-size: 18px;
    padding: 12px;
  }
}

@media screen and (max-width: 768px) {
  .option {
    font-size: 14px;
  }
}

.selected-slider {
  width: calc(50% - 8px);
  height: calc(100% - 16px);
  top: 8px;
  border-radius: 100px;
  background-color: var(--theme-bg-lb);
  z-index: -1;
  transition: all .3s ease;
}

.selected-left {
  right: 50%;
}

.selected-right {
  right: 8px;
}
</style>
