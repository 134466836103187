<template>
  <div ref="card" :class="isOpened && 'open-review__wrapper'"
       class="audio-review-card client-review-card position-relative review-text d-flex flex-column open-review">
    <div class="d-flex user-info-card">
      <img alt="" class="mr-1" src="@/assets/images/site/review-user-avatar.png">
      <span class="my-auto">
          {{ reviewerName }}
        </span>
    </div>

    <div class="text">
      {{ $t(reviewShort) }}
    </div>

    <div class="audio-review mt-auto h-100 w-100 d-flex">
      <div class="mt-auto d-flex w-100">
        <button class="play-audio-review my-auto" @click="togglePlayAudioReview">
          <transition-group class="d-flex" mode="out-in" name="spin-fade">
            <img v-if="nowPlay" key="2" alt="" src="@/assets/images/site/pause-sound.svg">
            <img v-else key="1" alt="" src="@/assets/images/site/play-sound.svg">
          </transition-group>
        </button>

        <div class="sound-wave my-auto mx-auto">
          <div v-for="(i, index) in soundwave" :class="index < fillSoundWaveCount && 'filled-sw'"
               :style="'height: ' + i + 'px;'"
               @click="setTime(index)"/>
        </div>

        <div class="duration my-auto ml-auto">
          {{ formattedLeaveDuration }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "client-review-card",
  props: {
    audioSrc: {type: String, required: true},
    reviewShort: {type: String, required: true},
    reviewFull: {type: String, required: true},
    reviewerName: {type: String, default: "Анонимный пользователь"},
  },
  data() {
    return {
      isOpened: false,
      duration: 0,
      nowPlay: false,
      soundwave: Array(30).fill(0).map(() => (Math.floor(Math.random() * 25) + 3)),
      timePlayed: 0,
      audio: new Audio(this.audioSrc)
    }
  },
  methods: {
    playReview() {
      this.isOpened = !this.isOpened
    },
    setOpenReview(v) {
      this.isOpened = v
    },
    openFullTextReview() {
      this.$bvModal.show("client_review_modal")
      this.$emit("select-review", {text: this.$t(this.reviewFull), name: this.reviewerName})
    },
    togglePlayAudioReview() {
      if (!this.nowPlay) {
        this.$emit("play-audio", this.index)
        this.audio.play()
      } else {
        this.audio.pause()
        this.nowPlay = false
      }
    },
    setTime(v) {
      this.audio.currentTime = this.duration * (v / 30)
    }
  },
  watch: {
    nowPlayingIndex(val) {
      if (val !== null && val !== this.index) {
        this.audio.pause()
      }
    }
  },
  computed: {
    leaveDuration() {
      return this.duration - this.timePlayed
    },
    formattedLeaveDuration() {
      let mins = (Math.floor((this.leaveDuration) / 60) + "").padStart(2, "0")
      let secs = (Math.floor((this.leaveDuration) % 60) + "").padStart(2, "0")

      return mins + ":" + secs
    },
    fillSoundWaveCount() {
      return Math.trunc(30 * (this.timePlayed / this.duration))
    }
  },
  mounted() {
    this.audio.addEventListener("loadeddata", () => {
      this.duration = this.audio.duration
    })

    this.audio.addEventListener("loadedmetadata", () => {
      this.duration = this.audio.duration
    })

    this.audio.addEventListener("playing", () => {
      this.nowPlay = true
    })

    this.audio.addEventListener("pause", () => {
      this.nowPlay = false
    })

    this.audio.addEventListener("timeupdate", (e) => {
      this.timePlayed = this.audio.currentTime.toFixed(0)
    })
  }
}
</script>

<style lang="scss" scoped>
@supports (aspect-ratio: 1) {
  .client-review-card {
    aspect-ratio: 1;
    border-radius: 32px;
    overflow: hidden;
  }
}

@supports not (aspect-ratio: 1) {
  .client-review-card {
    column-count: 1;
    border-radius: 32px;
    overflow: hidden;

    .client-review-card__inner {
      position: absolute;
    }
  }
}

.client-review-card__inner {
  height: 100%;
  padding: 24px;
  background: rgba(0, 0, 0, .6);
  color: white;
}

.review-text {
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 0;
  background: white;
  padding: 0 24px;
  overflow: hidden;
  transition: all .3s ease;
}

.open-review {
  height: 100%;
  padding: 24px;

  .text {
    font-size: 18px;
    margin-top: 16px;
  }
}

.open-review__wrapper {
  border: solid 1px #d9d9d9;
}

.play-img {
  transition: all .3s ease;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
}

.full-text-link {
  text-decoration: underline;
}

.user-info-card {
  img {
    object-fit: cover;
    border-radius: 100px;
    width: 48px;
  }

  span {
    font-weight: normal;
    font-size: 18px;
  }
}

.sound-wave {
  display: flex;

  div {
    transition: all .3s ease;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 3px;
    width: 3px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.18);
  }
}

@media screen and (max-width: 768px) {
  .sound-wave {
    div {
      margin-right: 2px;
    }
  }
}

.filled-sw {
  background: rgba(0, 0, 0, 0.32) !important;
}

.audio-review-card {
  border: 1px solid #d5d5d5;
}

.play-audio-review {
  border: 1px black solid;
  outline: none;
  border-radius: 50px;
  background: white;
  display: flex;
  padding: 8px;
  width: 30px;
  height: 30px;

  img {
    margin: auto;
  }
}
</style>

<style>
/* moving */
.spin-fade-move {
  transition: all 600ms ease-in-out 50ms;
}

/* appearing */
.spin-fade-enter-active {
  transition: all 400ms ease-out;
}

/* disappearing */
.spin-fade-leave-active {
  transition: all 200ms ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disappear to */
.spin-fade-enter,
.spin-fade-leave-to {
  opacity: 0;
  transform: rotate(180deg);
}
</style>
