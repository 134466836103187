<template>
  <div cols="12" class="d-flex justify-content-between flex-column">
    <div class="faq-item d-block d-sm-none">
      <img class="mr-1 miner_icon mt-0 mb-1" :src="image" alt="">
      <div class="faq-question d-flex justify-content-between" @click="toggleFooterLinkGroupList">
        <div class="faq-question mb-1 d-flex flex-column flex-md-row">
          <q-header color="#fff" :centered="false" size="s" class="mb-auto">
            {{header}}
          </q-header>
        </div>
        <span class="icon">
          <img src="@/assets/images/site/icons/plus_in_circle.svg" :class="!isOpen && 'icon-faq-plus'" alt="" class="icon-symbol">
        </span>
      </div>
      <b-collapse id="faq-item" :visible="isOpen">
        <div class="d-flex flex-column">
          <p class="miner_small_text">
            {{text}}
          </p>
        </div>
      </b-collapse>
    </div>
    <div class="flex-column d-sm-flex d-none">
      <div class="faq-question mb-1 d-flex">
        <img class="mr-1 miner_icon mt-0" :src="image" alt="">
        <q-header color="#fff" :centered="false" size="s" class="my-auto">
          {{header}}
        </q-header>
      </div>
      <div class="d-flex">
        <span class="miner_icon" style="height: 10px;"/>
        <p class="miner_small_text ml-1">
          {{text}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataCenterCollapse",
  props: {
    header: {type: String},
    text: {type: String},
    image: {type: String}
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleFooterLinkGroupList() {
      this.isOpen = !this.isOpen
    }
  },
}
</script>

<style scoped lang="scss">
.miner_small_text{
  color: white;
  line-height: 22px;
  width: 80%
}

.miner_icon {
  width: 50px;
}
.faq-question {
  font-size: 24px;

  span {
    cursor: pointer;
  }

  .icon {
    transition: all .3s ease;
    border: solid 1px var(--text-primary-color);
    border-radius: 100px;
    aspect-ratio: 1;
    width: 28px;
    height: 28px;
    display: flex;
    padding: 4px;

    .icon-symbol {
      transition: all .3s ease;
      width: 100%;
    }
  }
}

.icon-faq-plus {
  transform: rotate(45deg);
}

</style>
