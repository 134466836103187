<template>
  <div class="container container-px-lg">
    <div class="blue-section px-2 pt-2 d-flex flex-column-reverse flex-lg-row">
      <b-col class="" cols="12" lg="6">
        <img alt="" class="w-100 image" src="@/assets/images/site/girl-attending-live-webinar.png">
      </b-col>
      <b-col class="px-2 px-lg-3 pb-lg-2 pb-xl-4" cols="12" lg="6">
        <b-row class="position-relative justify-content-end">
          <b-col class="p-2 white-dialog" cols="12" md="9">
            <p><b>{{$t('lending.no-question.ans-1')}}</b></p>
          </b-col>
          <img alt="" class="white-tail position-absolute" src="@/assets/images/site/white_tail.svg">
        </b-row>
        <b-row class="position-relative justify-content-start mt-1">
          <b-col class="p-2 blue-dialog" cols="12" md="9" data-animated="animate__bounceIn">
            <p>{{$t('lending.no-question.ans-2')}}</p>
            <q-btn class="test-btn" small-padding variant="secondary" to="/register">{{$t('lending.no-question.btn')}}</q-btn>
          </b-col>
          <img alt="" class="blue-tail position-absolute" src="@/assets/images/site/blue_tail.svg">
        </b-row>
      </b-col>
    </div>
  </div>
</template>

<script>
export default {
  name: "i-have-no-question"
}
</script>

<style lang="scss" scoped>
.blue-section {
  background-color: var(--theme-accent-dark);
  border-radius: 48px;
}

.image {
  margin-bottom: -6.7%;
}

.white-dialog {
  p {
    color: var(--theme-accent-light);
    font-size: 18px;
    line-height: 1.5;
  }

  background-color: #fff;
  border-radius: 30px;
}

.blue-dialog {
  p {
    color: #fff;
    font-size: 18px;
    line-height: 1.5;
  }

  background-color: #383DBD;
  border-radius: 30px;
}

.white-tail {
  right: -3%;
  width: 11%;
  bottom: -1%;
}

.blue-tail {
  left: -3%;
  bottom: -10%;
}

.test-btn {
  font-size: 14px;
}

@media screen and (max-width: 1300px) and (min-width: 992px) {
  .image {
    margin-top: 10vh;
  }
}

@media screen and (max-width: 1140px) and (min-width: 992px) {
  .blue-tail {
    left: -5%;
    bottom: -5%;
  }

  .image{
    margin-top: 15vh;
  }
}


@media screen and (max-width: 1070px) and (min-width: 992px) {
  .image{
    margin-top: 18vh;
  }
}

</style>
