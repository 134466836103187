<template>
  <div class="crypto-news-element__wrapper">
    <div class="crypto_news_element d-flex flex-column flex-md-row">
      <div class="crypto-logo my-auto">
        <img :src="img" alt="" class="">
      </div>
      <span class="crypto_news_element_text ml-0 ml-md-1 mt-1 mt-md-auto mb-auto">{{ text }}</span>
    </div>
  </div>
</template>

<script>
import img from '@/assets/images/icons/cryptonews.png'

export default {
  name: "CryptoNewsElement",
  props: {
    img: {
      type: String, default: img,
    },
    text: {
      type: String, default: "Заработать на криптовалюте с Qubity.io сможет любой...",
    }
  }
}
</script>

<style lang="scss" scoped>
.crypto-news-element__wrapper {
  margin: 0 8px;
}

.crypto_news_element {
  background: #ffffff;
  border-radius: 130px;
  padding: 20px;
  width: 100%;

  img {
    object-fit: contain !important;
    width: 100%;
    object-position: center;
    margin: auto auto auto 0;
  }
}

@media screen and (max-width: 768px) {
  .crypto_news_element {
    border-radius: 32px!important;
  }
}


.crypto_news_element_text {
  white-space: break-spaces;
}

.crypto-logo {
  width: 150px;
  object-fit: cover;

  img {
    width: 100%;
  }
}
</style>
