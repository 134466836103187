<template>
  <div class="how-it-works-card d-flex d-md-block h-100">
    <div class="icon mb-1 mr-1">
      <img :src="icon" alt="">
    </div>
    <span>
      <slot>
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "EarnHowItWorkCard",
  props: {
    icon: {type: String}
  }
}
</script>

<style scoped>
.how-it-works-card {
  background-color: rgba(255, 255, 255, .3);
  padding: 20px;
  color: white;
  font-size: 18px;
  border-radius: 32px;
}
</style>
