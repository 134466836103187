<template>
  <b-container class="flex-row miner-card justify-content-between py-1">
    <b-row class="justify-content-between">

      <b-col class="d-flex align-items-center" cols="12" md="3" xl="2">
        <img :src="miner.medias[0].urls.original || '@/assets/imafes/miner_pic.png'" :alt="miner.media_alt" :title="miner.media_title" class="miner-img">
        <p v-if="isRecommend" class="recommend d-inline-block">{{$t('catalog.recommend')}}</p>
      </b-col>

      <b-col class="my-auto d-flex flex-xl-row flex-column" cols="12" md="9">

        <b-col class="my-auto" cols="12" xl="9">
          <b-row>
            <q-header :centered="false" color="var(--text-primary-color)" level="5" size="l"><b>{{
                miner.name
              }}</b>
            </q-header>
          </b-row>

          <b-row class="props">
            <b-col class="pl-0" cols="12" lg="6">
              <div class="d-flex justify-content-between">
                <p class="mb-0">{{$t('catalog.hashrate')}}:</p>
                <p class="mb-0">{{ miner.hashrate }} Th/s</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-0">{{$t('catalog.energy-consumption')}}:</p>
                <p class="mb-0">{{ miner.energy_usage }} W</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-0">{{$t('catalog.currency')}}:</p>
                <p class="mb-0">{{ 'BTC' }}</p>
              </div>
            </b-col>
            <b-col class="pl-0 pl-lg-1 v-shade" cols="12" lg="6">
              <div class="d-flex justify-content-between">
                <p class="mb-0">{{$t('catalog.income-per-day')}}:</p>
                <p class="mb-0">{{ daily_profit }} ВТС ≈ {{ daily_currency.formatMoney(2, "", " ") }}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-0">{{$t('catalog.rate-of-return')}}:</p>
                <p class="mb-0">{{ payback }}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-0">{{$t('catalog.start-mining')}}:</p>
                <p class="mb-0">{{$t('catalog.in')}} {{ miner.delivery_period }} {{$t('catalog.days')}}</p>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col class="px-0" cols="12" xl="3">
          <b-row class="text-left text-xl-center flex-row flex-xl-column justify-content-start justify-content-md-between px-2 mt-1 mt-lg-1">
            <b-col class="my-auto d-xl-block d-inline-flex my-auto p-0" cols="7" md="4" xl="12">
              <q-header color="var(--text-primary-color)" level="6" size="xl"><b>{{ miner.price_amount.formatMoney(0,"$", " ") }}</b></q-header>
            </b-col>
            <b-col class="text-right text-xl-center my-auto d-inline-flex justify-content-xl-around justify-content-end" cols="5" md="8" lg="12">
              <template v-if="ifIsInCart && miners.cart[miner.id].count > 0">
                <div class="d-flex flex-xl-column justify-content-end">
                  <b-form-spinbutton
                      v-model="miners.cart[miner.id].count"
                      :min="0"
                      :max="500"
                      class="my-auto mx-xl-auto"
                      inline
                      size="sm"
                      @input="preferCountValueChanged( $event )"
                  />
                </div>
              </template>
              <template v-else>
                <b-button class="px-2 mobile-btn" variant="mobile-btn" @click="addToCart()">{{$t('catalog.btns.add-to-cart')}}</b-button>
              </template>
            </b-col>
          </b-row>
        </b-col>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Contracts from "@/modules/user/contracts"
import miners from "@/modules/contents/miners";
import Utils from "@/modules/utils/"

export default {
  name: "EarnOptionMinerCard",
  props: {
    miner: Object,
    isRecommend: Boolean,
    diff_percent: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      miners,
      period: 37,
      market_price_percent: 3,
      ticker: "USD"
    }
  },
  computed: {
    ifIsInCart() {
      return this.miners.cart.hasOwnProperty( this.miner.id );
    },

    payback() {
      let total_profit_usd = this.period_profit * this.market_price_usd - this.fee_usd - this.energy_usd - this.service_usd;

      let aver_usd = total_profit_usd / this.miner.service_period;

      // окупаемость
      let days = parseInt((this.miner.price_amount) / aver_usd);

      return days + ' ' + Utils.declOfNum( days, [ 'день', 'дня', 'дней' ]);    
    },

    daily_currency() {
      return (this.daily_profit * this.$blockchain.tickers.values["USD"].last);
    },

    daily_currency_with_payback() {
      return (this.daily_profit_with_payback * this.$blockchain.tickers.values["USD"].last);
    },

    daily_profit() {
      let hashrate = this.miner.hashrate;

      if( this.miners.cart.hasOwnProperty( this.miner.id ) ) {
        hashrate = hashrate * this.miners.cart[this.miner.id].count;
      }

      return ( ((84600 * Contracts.values.R * ( Contracts.values.H * hashrate )) / ( Contracts.values.D * Math.pow(2, 32) )) ).toFixed(4);
    },

    daily_profit_with_payback() {
      let hashrate = this.miner.hashrate;

      return ( ((84600 * Contracts.values.R * ( Contracts.values.H * hashrate )) / ( Contracts.values.D * Math.pow(2, 32) )) ).toFixed(8);
    },

    performance() {
      return Math.trunc(this.miner.energy_usage / this.miner.hashrate);
    },

    energy_price() {
      return (( (this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 ) ;
    },

    energy_usd() {
        let count = 1;
        
        if( this.ifIsInCart ) {
            count = this.miners.cart[this.miner.id].count;              
        }

        return ((((this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 ) * this.miner.service_period ) * count
    },

    fee_usd() {
        return (this.period_profit * 0.025) * this.market_price_usd;
    },

    service_usd() {
        return (this.period_profit * 0.05) * this.market_price_usd;
    },
            
    energy_price_per_day() {
      let count = 1;
      if( this.miners.cart.hasOwnProperty( this.miner.id ) ) {
        count = this.miners.cart[this.miner.id].count;
      }

      return (( (this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 )  * count ;
    },

    energy_price_per_day_btc() {
      return this.energy_price_per_day / this.$blockchain.tickers.values["USD"].last;
    },

    daily_profit_clean() {
      let fee = this.daily_profit * 0.025;
      let service = (this.daily_profit - fee) * 0.05;

      return (this.daily_profit - fee - service - this.energy_price_per_day_btc).toFixed(4);
    },

    comission() {
      return this.daily_currency * 0.025 ;
    },

    comission_with_payback() {
      return this.daily_currency_with_payback * 0.025 ;
    },

    service() {
      return (this.daily_currency - this.comission) * 0.05;
    },

    service_with_payback() {
      return (this.daily_currency_with_payback - this.comission_with_payback) * 0.05;
    },

    start_at() {
      var today = new Date();
      today.setDate(today.getDate() + this.miner.delivery_period );
      return today;
    },

    end_at() {
      var today = new Date();
      today.setDate(today.getDate() + this.miner.delivery_period + this.miner.service_period );
      return today;
    },

    market_price_usd() {
        return this.$blockchain.tickers.values.USD.last * (Math.pow((1 + this.market_price_percent / 100), this.period))
    },

    energy_period() {
      let count = 1;
      
      if( this.ifIsInCart ) {
          count = this.miners.cart[this.miner.id].count;              
      }

      if (User.currency === 'BTC') {
          if (this.market_price_percent == 0) {
              return (((((this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 ) * this.miner.service_period ) * count) / this.$blockchain.tickers.values['USD'].last
          } else {
              // const month_period = 30;
              // return (((month_period * (1 + (this.market_price_percent / 100)) * (1 - Math.pow(1 + (this.market_price_percent / 100), -(this.miner.service_period / 30.5 - 1) ))) / this.$blockchain.tickers.values['USD'].last)) *36 * count;

              let energy_btc = 0;
              let usd_rate = this.$blockchain.tickers.values['USD'].last;

              let month_energy_usage_usd = ((this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 * 30.5;

              for (let i = 0; i < parseInt(this.miner.service_period / 30); i++) {
                  energy_btc += month_energy_usage_usd / usd_rate;
                  usd_rate = usd_rate * (1 + (this.market_price_percent / 100));
              }

              return energy_btc * count;
          }
      } else {
          return ((((this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 ) * this.miner.service_period ) * count
      }
    },

    fee_period() {
        return (this.period_profit * 0.025) * this.market_price;
    },
    
    service_period() {
        return (this.period_profit * 0.05) * this.market_price;
    },

    period_profit() {
        // return ((86400 * 30 * this.period * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.miner.hashrate )) / ( this.difficult * Math.pow(2, 32) ));

         
        let profit = 0;
        let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
        let diff = parseInt(this.$user.contracts.values.D);

        for (let i = 0; i < this.period; i++) {
            profit += (86400 * 30 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.miner.hashrate )) / ( diff * Math.pow(2, 32) );

            diff += temp;
        }

        return profit;
    },

    difficult() {
        let D = parseInt(this.$user.contracts.values.D);

        let temp = D * (this.diff_percent / 100);
        let growing = temp * this.period;
        
        var diff = D + growing;
                        
        if( diff <= 20000000000 ) {
            diff = 20000000000;
        }
                        
        return parseInt(diff);
        
    },

    market_price() {
        // формула вычета сложно процента 
        if (this.ticker === 'USD') {
            return this.$blockchain.tickers.values[this.ticker].last * (Math.pow((1 + this.market_price_percent / 100), this.period))
        } else if  (this.ticker === 'RUS') {
            return this.$blockchain.tickers.values[this.ticker].last;
        } else {
            return 1;
        }
    },

    market_price_usd() {
          return this.$blockchain.tickers.values.USD.last * (Math.pow((1 + this.market_price_percent / 100), this.period))
    },
  },
  methods: {
    addToCart() {
      if (this.miners.cart.hasOwnProperty(this.miner.id)) {
        this.$set(this.miners.cart, this.miner.id, {
          miner: this.miner,
          count: this.miners.cart[this.miner.id].count + 1
        });
      } else {
        this.$set(this.miners.cart, this.miner.id, {
          miner: this.miner,
          count: 1
        });
      }
    },
    preferCountValueChanged(count) {
      if (count === 0) {
        this.$delete(this.miners.cart, this.miner.id);
      }
    },
    goToCheckOut() {
      if (this.$user.isAuth()) {

        this.$router.push({name: 'user-miners-checkout'});

      } else {

        sessionStorage.setItem("after:auth", JSON.stringify({
          "type": "buy",
          "data": {}
        }));

        this.$router.push({name: 'auth-login'});
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.recommend {
  position: absolute;
  left: 30px;
  top: 10px;
  color: var(--theme-bg-light);
  background: #FF5822;
  border-radius: 40px;
  padding: 3px 9px;
}

.mobile-btn {
  white-space: nowrap;
  display: block;
  color: var(--theme-accent-light) !important;
  font-size: 18px;

  padding: 14px 8px;
  border: 1px solid rgba(26, 86, 255, 0.18);
  border-radius: 35px;
  transition: .3s;

  a {
    padding: 0;
    color: inherit !important;
  }
}

.props {
  white-space: nowrap
}

.v-shade {
  border-left: 1px solid rgba(0, 0, 0, .1);
}

.mobile-btn:hover {
  border-color: transparent;
  background-color: rgba(26, 86, 255, 0.1);
}

.miner-img {
  width: 100%;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .miner-img {
    width: 50%;
  }

  .recommend {
    left: 20px;
    top: 20px;

    font-size: 18px;
    padding: 4px 11px;
  }
}

.miner-card {
  background: #fcfcfc;
  border-radius: 32px;
  box-shadow: -14px 43px 53px rgba(81, 78, 120, 0.04);
}

@media screen and (max-width: 992px) {
  .v-shade {
    border: none;
  }
  .recommend {
    left: 50px;
    top: 15px;
  }
}

@media screen and (max-width: 576px) {
  .recommend {
    left: 15%;
    font-size: 14px;
    padding: 3px 9px;
  }
}
</style>
