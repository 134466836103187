<template>
  <section class="container container-pl-lg sm-off-pr pr-0 py-4">
    <b-modal id="client_review_modal"
             centered
             ok-only
             :ok-title="$t('lending.btns.ready')"
             :title="$t('lending.client-reviews.c-feedback')"
    >
      {{ selectedReview && selectedReview.text }}
    </b-modal>
    <div id="reviews" class="d-flex justify-content-between flex-md-row flex-column">
      <b-col class="d-flex my-auto" cols="12" md="6">
        <q-header :centered="false">
          <b>{{$t('lending.client-reviews.header-a')}}<span class="client-reviews-header position-relative">{{$t('lending.client-reviews.header-b')}}<img
              alt="" src="@/@core/assets/images/btn_sweep.svg"></span>
            <br>
            {{$t('lending.client-reviews.header-c')}}</b>
        </q-header>
      </b-col>
      <b-col class="d-flex p-0" cols="12" lg="4" md="5">
        <b-col class="special-offer position-relative d-flex mr-n2 px-1 py-2" cols="6">
          <div class="m-auto d-flex d-md-none d-xl-flex flex-column">
            <q-header color="var(--theme-accent-light)" level="3" size="m">
              <b>27.6 </b><span style="font-size: 60%">BTC</span>
            </q-header>
            <q-header class="mb-0" color="var(--theme-accent-light)" level="6" size="m">
              <span style="font-size: 90%">{{$t('lending.client-reviews.earned')}}</span>
            </q-header>
          </div>
          <div class="m-auto d-none d-md-flex d-xl-none flex-column">
            <q-header color="var(--theme-accent-light)" level="3" size="s">
              <b>27.6 </b><span style="font-size: 60%">BTC</span>
            </q-header>
            <q-header class="mb-0" color="var(--theme-accent-light)" level="6" size="s">
              <span style="font-size: 90%">{{$t('lending.client-reviews.earned')}}</span>
            </q-header>
          </div>
        </b-col>
        <b-col class="special-offer position-relative d-flex mr-n2" cols="6">
          <div class="m-auto d-flex d-md-none d-xl-flex flex-column">
            <q-header color="var(--theme-accent-light)" level="3" size="m">
              <b>6 328</b>
            </q-header>
            <q-header class="mb-0" color="var(--theme-accent-light)" level="6" size="m">
              <span style="font-size: 90%">{{$t('lending.client-reviews.users')}}</span>
            </q-header>
          </div>
          <div class="m-auto d-none d-md-flex d-xl-none flex-column">
            <q-header color="var(--theme-accent-light)" level="3" size="s">
              <b>6 328</b>
            </q-header>
            <q-header class="mb-0" color="var(--theme-accent-light)" level="6" size="s">
              <span style="font-size: 90%">{{$t('lending.client-reviews.users')}}</span>
            </q-header>
          </div>
        </b-col>
      </b-col>
    </div>
    <q-carousel class="mt-3 clients-reviews" control-class="container-pr-lg">
      <q-slide v-for="(r, index) in reviews">
        <client-video-review-card
            v-if="r.type === 'video'"
            :bg-img="r.bgImg || require('@/assets/images/review-bg-mock.png')"
            :review-full="r.textFull"
            :review-short="r.textShort"
            :video-src="r.videoSrc"
            @select-review="onReviewSelect"
        >
        </client-video-review-card>
        <client-audio-review-card
            v-else
            :audio-src="r.audioSrc"
            :review-full="r.textFull"
            :review-short="r.textShort"
            @select-review="onReviewSelect"
        >
        </client-audio-review-card>
      </q-slide>
    </q-carousel>
  </section>
</template>

<script>
import QCarousel from "@/views/Main/main-design-v2/components/QCarousel";
import QSlide from "@/views/Main/main-design-v2/components/QSlide";
import ClientAudioReviewCard from "@/views/Main/main-design-v2/views/home/components/ClientAudioReviewCard";
import ClientVideoReviewCard from "@/views/Main/main-design-v2/views/home/components/ClientVideoReviewCard";

export default {
  name: "client-reviews",
  components: {QCarousel, QSlide, ClientAudioReviewCard, ClientVideoReviewCard},
  data() {
    return {
      nowPlayingIndex: null,
      selectedReview: null,
      reviews: [
        {
          type: "video",
          videoSrc: "https://www.youtube.com/embed/QF8-fV1-6Ro",
          // bgImg: "https://kod.ru/content/images/2020/10/IMG_1098.JPG",
          textShort: "lending.client-reviews.1-textShort",
          textFull: "lending.client-reviews.1-textFull"
        },
        // {
        //   type: "audio",
        //   audioSrc: "https://audio.jukehost.co.uk/5UbEDtx4nnZ7rondV2c2Oz2ieNWsotte",
        //   textShort: "shortTextReview",
        //   textFull: "Не мог решиться начать майнить со времен, когда Биток был 10.000$, пока не нашел Qubity.io"
        // },
        {
          type: "video",
          videoSrc: "https://www.youtube.com/embed/2RcRDG4SGhQ",
          // bgImg: "https://kod.ru/content/images/2020/10/IMG_1098.JPG",
          textShort: "lending.client-reviews.2-textShort",
          textFull: "lending.client-reviews.2-textFull"
        },
        {
          type: "video",
          videoSrc: "https://www.youtube.com/embed/zPu9IU6Ww4I",
          // bgImg: "https://kod.ru/content/images/2020/10/IMG_1098.JPG",
          textShort: "lending.client-reviews.3-textShort",
          textFull: "lending.client-reviews.3-textFull"
        },
        {
          type: "video",
          videoSrc: "https://www.youtube.com/embed/KTMFffTKH6o",
          // bgImg: "https://kod.ru/content/images/2020/10/IMG_1098.JPG",
          textShort: "lending.client-reviews.4-textShort",
          textFull: "lending.client-reviews.4-textFull"
        },
        {
          type: "video",
          videoSrc: "https://www.youtube.com/embed/fu8NpDJnQes",
          // bgImg: "https://kod.ru/content/images/2020/10/IMG_1098.JPG",
          textShort: "lending.client-reviews.5-textShort",
          textFull: "lending.client-reviews.5-textFull"
        }
      ]
    }
  },
  methods: {
    onPlayAudio(e) {
      this.nowPlayingIndex = e
    },
    onReviewSelect(r) {
      this.selectedReview = r
    }
  }
}
</script>

<style lang="scss" scoped>

.special-offer {
  border: 1px solid #4D68F7;
  box-sizing: border-box;
  transform: rotate(-6deg);
  border-radius: 55%;
  left: -16px;
}

@media screen and (max-width: 768px) {
  .special-offer {
    left: 0;
    width: 80%;
  }

  .lines {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sm-off-pr {
    padding-right: 0;
  }
}

.client-reviews-header {
  img {
    transform: rotate(110deg);
    position: absolute;
    top: -10px;
  }
}
</style>

<style lang="scss">
@supports not (aspect-ratio: 1) {
  .clients-reviews {
    .audio-review-card::before {
      padding: 0!important;
    }

    .q-slide {
      height: 100%;
    }
  }
}
</style>
